import React, { useEffect, useState } from 'react'
import { Col } from 'react-bootstrap'
import { useNavigation } from '../../../Common/Hooks/useNavigation'
import { Buttons } from '../../../Components/Common/Buttons'
import { LoadingSpinner } from '../../../Components/Common/LoadingSpinner'
import { BaseContentView } from '../../../Components/views/BaseContentView'
import { IServicesResponse } from '../../../Data/interfaces/Common/ServicesResponse'
import { IEquipmentAlarms } from '../../../Data/interfaces/Masters/Equipment/Equiment'
import { getSingleEquipmentAlarms } from '../../../Services/Masters/Equipments/EquimentsServices'
import FormAlarmEquipment from './Components/FormAlarmEquipment'

function UpdateAlarmsEquipment() {

  //hooks
  const { stateAs, goto } = useNavigation()
  const { data } = stateAs<any>()

  //states
  const [alarmsEquipment, setAlarmsEquipment] = useState<IEquipmentAlarms>()
  const [isLoadModule, setIsLoadModule] = useState<boolean>(true)

  //effects
  useEffect(() => {
    const fetchData = async () => {
      setIsLoadModule(true)
      const responseService: IServicesResponse<IEquipmentAlarms> = await getSingleEquipmentAlarms(data.id)
      responseService.appearanceMessage === 'success' &&
        setAlarmsEquipment(responseService.responseMessage as IEquipmentAlarms)
      setIsLoadModule(false)
    }

    data ? fetchData() : goto.absolute('base.equipments_alarms')
  }, [data])

  return (
    <BaseContentView title="Modificar alarma de equipo">
      {
        isLoadModule
          ? (<LoadingSpinner />)
          : (
            <>
              <Col className="mb-4"><Buttons.Back /></Col>
              <Col className="mb-3 p-0">
                <FormAlarmEquipment dataInitial={alarmsEquipment} isUpdate={true} />
              </Col>
            </>
          )}
    </BaseContentView>)
}

export default UpdateAlarmsEquipment



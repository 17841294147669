export class FormatUtils {
  //formato para id con relleno de 0 al inicio
  static id = (id: number | string) => {
    return id.toString().padStart(6, '0')
  }
}

export const removeAccents = (value: string) => {
  return value.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
}

import { AxiosError, AxiosResponse } from 'axios'
import { Option } from 'react-dual-listbox'
import { ax } from '../../../Common/Utils/AxiosCustom'
import { HttpUtils } from '../../../Common/Utils/HttpUtils'
import { IServicesResponse } from '../../../Data/interfaces/Common/ServicesResponse'
import { IEquipmentAlarms } from '../../../Data/interfaces/Masters/Equipment/Equiment'
import { IEquimentDataShort } from '../../../Data/interfaces/Reports/UsageReports'

export const getEquipmentSimpleService = async () => {
  const serviceResponse: IServicesResponse<IEquimentDataShort> = {
    appearanceMessage: 'error',
  }

  await ax
    .get<IEquimentDataShort>('equipment', { params: { all_equipments: 1 } })
    .then((res: AxiosResponse) => {
      serviceResponse.appearanceMessage = 'success'
      serviceResponse.responseMessage = res.data
    })
    .catch((error: AxiosError) => {
      serviceResponse.responseMessage = HttpUtils.localizedErrorMsg(error)
    })

  return serviceResponse
}

export const postEquipmentService = async ({
  data,
  onFinish,
  onSuccess,
}: {
  data: any
  onSuccess?: () => void
  onFinish: (error: IServicesResponse<undefined>) => void
}) => {
  const response: IServicesResponse<undefined> = {
    appearanceMessage: 'error',
  }
  await ax
    .post('equipment', data)
    .then((res: AxiosResponse) => {
      response.appearanceMessage = 'success'
      response.responseMessage = res.data
      onSuccess && onSuccess()
    })
    .catch((error: AxiosError) => {
      response.appearanceMessage = 'error'
      response.responseMessage = HttpUtils.localizedErrorMsg(error)
    })
    .finally(() => {
      onFinish(response)
    })
}

export const setEquipmentService = async ({
  id,
  data,
  onFinish,
  onSuccess,
}: {
  id: number
  data: any
  onSuccess?: () => void
  onFinish: (error: IServicesResponse<undefined>) => void
}) => {
  const response: IServicesResponse<undefined> = {
    appearanceMessage: 'error',
  }
  await ax
    .patch(`equipment/${id}`, data)
    .then((res: AxiosResponse) => {
      response.appearanceMessage = 'success'
      response.responseMessage = res.data
      onSuccess && onSuccess()
    })
    .catch((error: AxiosError) => {
      response.appearanceMessage = 'error'
      response.responseMessage = HttpUtils.localizedErrorMsg(error)
    })
    .finally(() => {
      onFinish(response)
    })
}

export const getNotyfiableUsersService = async ({ id_equipment }:{id_equipment:number}) => {
  const serviceResponse: IServicesResponse<Option<unknown>[]> = {
    appearanceMessage: 'error',
  }

  await ax.get<Option<unknown>[]>('equipments_alarms/notyfiable_users', { params: { id_equipment } })
    .then((res: AxiosResponse) => {
      serviceResponse.appearanceMessage = 'success'
      serviceResponse.responseMessage = res.data.data
    })
    .catch((error: AxiosError) => {
      serviceResponse.responseMessage = HttpUtils.localizedErrorMsg(error)
    })

  return serviceResponse
}

export const postEquipmetAlarmsService = async (data: IEquipmentAlarms) => {
  const serviceResponse: IServicesResponse<Option<unknown>[]> = {
    appearanceMessage: 'error',
  }
  await ax.post('equipments_alarms', data)
    .then((res: AxiosResponse) => {
      serviceResponse.appearanceMessage = 'success'
      serviceResponse.responseMessage = res.data
    })
    .catch((error: AxiosError) => {
      serviceResponse.appearanceMessage = 'error'
      serviceResponse.responseMessage = HttpUtils.localizedErrorMsg(error)
    })

  return serviceResponse
}

export const setEquipmetAlarmsService =  async (data: IEquipmentAlarms) => {
  const serviceResponse: IServicesResponse<Option<unknown>[]> = {
    appearanceMessage: 'error',
  }
  await ax.patch('equipments_alarms', data)
    .then((res: AxiosResponse) => {
      serviceResponse.appearanceMessage = 'success'
      serviceResponse.responseMessage = res.data
    })
    .catch((error: AxiosError) => {
      serviceResponse.appearanceMessage = 'error'
      serviceResponse.responseMessage = HttpUtils.localizedErrorMsg(error)
    })

  return serviceResponse
}


export const getSingleEquipmentAlarms = async (equipment_id: number) => {
  const serviceResponse: IServicesResponse<IEquipmentAlarms> = {
    appearanceMessage: 'error',
  }

  await ax.get(`equipments_alarms/${equipment_id}`)
    .then((res: AxiosResponse) => {
      serviceResponse.appearanceMessage = 'success'
      serviceResponse.responseMessage = res.data
    })
    .catch((error: AxiosError) => {
      serviceResponse.appearanceMessage = 'error'
      serviceResponse.responseMessage = HttpUtils.localizedErrorMsg(error)
    })

  return serviceResponse
}
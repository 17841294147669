import React, { useEffect, useState } from 'react'
import { Col } from 'react-bootstrap'
import { useNavigation } from '../../../Common/Hooks/useNavigation'
import { Buttons } from '../../../Components/Common/Buttons'
import { LoadingSpinner } from '../../../Components/Common/LoadingSpinner'
import { BaseContentView } from '../../../Components/views/BaseContentView'
import { IClient } from '../../../Data/interfaces/Masters/Clients/Client'
import FormClient from './Components/FormClient'

const UpdateClient = () => {
  //hooks
  const { stateAs, goto } = useNavigation()
  const state: any = stateAs()

  //states
  const [client, setClient] = useState<IClient>()
  const [isLoadModule, setIsLoadModule] = useState<boolean>(true)

  //effects
  useEffect(() => {
    setIsLoadModule(true)
    if (state === undefined) {
      goto.absolute('base.client')
    } else {
      setClient(state.data as IClient)
    }
    setIsLoadModule(false)
  }, [])

  return (
    <BaseContentView title="Modificar cliente">
      {isLoadModule ? (
        <LoadingSpinner />
      ) : (
        <>
          <Col className="mb-4">
            <Buttons.Back />
          </Col>
          <Col className="mb-3 p-0">
            <FormClient dataInitial={client} isUpdate={true} />
          </Col>
        </>
      )}
    </BaseContentView>
  )
}

export default UpdateClient

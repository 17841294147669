import { AxiosError, AxiosResponse } from "axios"
import { ax } from "../../../Common/Utils/AxiosCustom"
import { HttpUtils } from "../../../Common/Utils/HttpUtils"
import { IServicesResponse } from "../../../Data/interfaces/Common/ServicesResponse"

export const getDataDashboardService = async () => {
  const serviceResponse: IServicesResponse<any> = {
    appearanceMessage: 'error',
  }

  await ax
    .get<any>('equipment/dataFromDashboard')
    .then((res: AxiosResponse) => {
      serviceResponse.appearanceMessage = 'success'
      serviceResponse.responseMessage = res.data
    })
    .catch((error: AxiosError) => {
      serviceResponse.responseMessage = HttpUtils.localizedErrorMsg(error)
    })

  return serviceResponse
}
import React, { useState, useCallback } from 'react'
import { Col } from 'react-bootstrap'
import { $u } from '../../../Common/Utils/Reimports'
import { CustomSelect } from '../../../Components/Forms/CustomSelect'
import { ListaBase } from '../../../Components/views/ListaBase'
import { UsuarioColumns } from '../../../Data/ColumnsTable/Usuario'
import { IUsuario } from '../../../Data/interfaces/Config/Usuario'

export const IndexUsers = () => {
  // const [search, doSearch] = useSearch();
  // const { meta } = useLocalization();
  // const { intl } = useFullIntl();
  // const { goto } = useNavigation();
  // const { gotoModify } = useCommonRoutes();

  // const [filter, setFilter] = useState<{ tipo: string; usuario?: IUsuario }>({
  // 	tipo: '1'
  // });

  // const customFilter = useCallback((x: IUsuario): boolean => {
  // 	if (filter.tipo === '-1') {
  // 		return true;
  // 	}

  // 	if (x.activo === true && filter.tipo === '1') {
  // 		return true;
  // 	}

  // 	return x.activo === false && filter.tipo === '0';

  // }, [filter]);

  return (
    <>
      <ListaBase<IUsuario>
        title="titles:users"
        source={'user'}
        permission="users"
        columns={UsuarioColumns}
        paginationServe={true}
        // customFilter={customFilter}
      >
        {/* <Col>
					<br className='mb-2' />

					<CustomSelect
						preSelect={filter.tipo}
						onChange={(e) => {
							const val = e.value;
							setFilter((s) => $u(s, { tipo: { $set: val } }));
						}}
						options={[{
							label: 'labels:all',
							value: '-1'
						},
						{
							label: 'labels:active',
							value: '1'
						},
						{
							label: 'labels:inactive',
							value: '0'
						}]}
					/>
				</Col> */}
      </ListaBase>
    </>
  )
}

import { useToasts } from 'react-toast-notifications'
import { IServicesResponse } from '../../Data/interfaces/Common/ServicesResponse'
import { useFullIntl } from './useFullIntl'

export const useShowAlertsRequest = () => {
  const { addToast } = useToasts()
  const { capitalize: caps } = useFullIntl()

  const showToast = <T extends unknown>({
    msgs,
  }: {
    msgs: IServicesResponse<T>
  }) => {
    const typeMensaje = typeof msgs.responseMessage
    switch (typeMensaje) {
      case 'object':
        if (Array.isArray(msgs.responseMessage)) {
          msgs.responseMessage.forEach((msg) => {
            addToast(caps(msg), {
              appearance: msgs.appearanceMessage,
              autoDismiss: true,
            })
          })
        } else {
          Object.entries(
            msgs.responseMessage as Record<string, string[]>
          ).forEach(([key, value]) => {
            if (Array.isArray(value)) {
              value.forEach((msg: string) => {
                addToast(caps(msg), {
                  appearance: msgs.appearanceMessage,
                  autoDismiss: true,
                })
              })
            } else {
              addToast(caps(value), {
                appearance: msgs.appearanceMessage,
                autoDismiss: true,
              })
            }
          })
        }
        break

      case 'string':
        addToast(caps(msgs.responseMessage as string), {
          appearance: msgs.appearanceMessage,
          autoDismiss: true,
        })
        break
    }
  }

  return showToast
}

import { AxiosError, AxiosResponse } from 'axios'
import { AppearanceTypes } from 'react-toast-notifications'
import { ax } from '../Common/Utils/AxiosCustom'
import { HttpUtils } from '../Common/Utils/HttpUtils'
import { IChangePassword } from '../Data/interfaces/Auth/IAuth'
import { IServicesResponse } from '../Data/interfaces/Common/ServicesResponse'

/**
 * cambiar contraseña
 * @param data datos del formulario
 * @returns object
 */
export const ServiceChangePassword = async (data: IChangePassword) => {
  const dataSend = {
    password: data?.password,
    old_password: data.old_password,
    confirm_password: data.confirm_password,
  }
  const serviceResponse: IServicesResponse<unknown> = {
    appearanceMessage: 'error',
  }
  await ax
    .patch('auth/change-password', dataSend)
    .then((res: AxiosResponse) => {
      serviceResponse.appearanceMessage = 'success'
      serviceResponse.responseMessage = res.data
    })
    .catch((error: AxiosError) => {
      serviceResponse.responseMessage = HttpUtils.localizedErrorMsg(error)
    })

  return serviceResponse
}

import React from 'react'
import { ListaBase } from '../../../Components/views/ListaBase'
import { ClientsColumns } from '../../../Data/ColumnsTable/Clients'
import { IClient } from '../../../Data/interfaces/Masters/Clients/Client'

export const IndexClients = () => {
  return (
    <>
      <ListaBase<IClient>
        title="titles:clients"
        source={'client'}
        permission="client"
        columns={ClientsColumns}
        paginationServe={true}
      ></ListaBase>
    </>
  )
}

import React, { CSSProperties, useEffect, useState } from 'react'
import { Col } from 'react-bootstrap'
import { ColorsAlarms } from '../../../../Common/Const/Enums/ColorsAlarms'
import { LoadingSpinner } from '../../../../Components/Common/LoadingSpinner'

interface IBoxValueGaugeProps {
  title: string
  value: number | undefined
  unit?: string
  supUnit?: string
  enableAlarm?: boolean
  isLoading?: boolean
  isInlineUnit?: boolean
}

function BoxValueGauge({ title, value, unit, supUnit, isInlineUnit = false, enableAlarm, isLoading = false }: IBoxValueGaugeProps) {
  const stylesBox: CSSProperties = {
    borderRadius: '0.25rem',
    outline: '2px solid #002060',
    flex: '1 1 auto',
    flexBasis: 0,
    marginBottom: '0.5rem',
    minWidth: 0,
    minHeight: 0,
  }

  const styleTitleBox: CSSProperties = {
    background: '#002060',
    height: '25%',
    color: '#fff'
  }

  const styleValueBox: CSSProperties = {
    height: '75%',
    color: '#002060',
    fontSize: '3vh',
    fontWeight: 'bold',
    overflowX: 'auto',
    overflowY: 'hidden',
    background: 'transparent'
  }

  //state
  const [sizeFromValue, setsizeFromValue] = useState<string>('xx-large')
  const [styleValue, setStyleValue] = useState<CSSProperties>(styleValueBox)

  const getColorStatus = (value: number) => {
    return value >= 33 ? (value >= 66 ? ColorsAlarms.Green : ColorsAlarms.Yellow) : ColorsAlarms.Red
  }

  useEffect(() => {
    const valueSize = value ? value.toString().length : 0
    let sizeFromValue = 'xx-large'
    if (valueSize > 12) {
      sizeFromValue = 'large'
    } else if (valueSize > 9) {
      sizeFromValue = 'x-large'
    }

    setsizeFromValue(sizeFromValue)

    if (enableAlarm) {
      setStyleValue({ ...styleValue, background: value && !isLoading ? getColorStatus(Number(value)) : 'transparent' })
    }
  }, [value, isLoading])

  return (
    <div className="p-0" style={stylesBox}>
      <Col className='d-flex align-items-center justify-content-center p-0' style={styleValue}>
        {
          isLoading ? (
            <LoadingSpinner />
          ) : (
            <Col className="px-2 text-center">
              <Col className='px-0'
                style={{ fontSize: sizeFromValue, whiteSpace: 'nowrap' }}>
                {value}{isInlineUnit && unit}{isInlineUnit && supUnit && (<sup>{supUnit}</sup>)}
              </Col>
              {
                (unit && !isInlineUnit) && (
                  <Col className='px-0 ' style={{ fontSize: 'large' }}>
                    <span>{unit}{supUnit && (<sup>{supUnit}</sup>)}</span>
                  </Col>
                )
              }
            </Col>
          )
        }
      </Col>
      <Col sm={12} className='d-flex align-items-center ' style={styleTitleBox}>
        <Col className="d-flex justify-content-center">
          <b>{title}</b>
        </Col>
      </Col>
    </div>
  )
}

export default BoxValueGauge
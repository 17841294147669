import { Fragment, useState, useEffect } from 'react'
import React from 'react'
import { useFullIntl } from '../../Common/Hooks/useFullIntl'
import { useRut } from '../../Common/Hooks/useRut'
import { FieldError } from 'react-hook-form'
import { ONLY_NUMBER } from '../../Common/Const/Patterns'
interface Props {
  label?: string
  name?: string
  id?: string
  value?: string
  errors?: string[]
  className?: string
  readonly?: boolean
  onlyNumber?: boolean
  isValidateRut?: boolean
  //filterPattern?:RegExp
  placeholder?: string
  onChange?: (e: string) => void
  hidden?: boolean
  disabled?: boolean
  type?: string
  errorForm?: FieldError
  industry?: boolean
  isLabelRequired?: boolean
}

export const Textbox = React.forwardRef(
  (props: Props, ref: React.Ref<HTMLInputElement>) => {
    const { capitalize: caps } = useFullIntl()
    const { onChange } = props

    const [init, setInit] = useState(false)
    // const [value, setValue] = useState<string>()

    const { validRut, rutFormat, rutClearFormat } = useRut()

    // useEffect(() => {
    //   setValue(props.value)
    // }, [props.value])

    useEffect(() => {
      if (init) {
        return
      }
      if (props.value != null && onChange != null) {
        onChange(props.value)
        setInit(true)
      }
    }, [init, setInit, onChange, props.value])

    return (
      <Fragment>
        {props.label && (
          <label>
            <b>
              {props.industry
                ? caps(props.name + '.' + localStorage.getItem('industria'))
                : caps(props.label)}
              {props.isLabelRequired && (
                <span className="text-danger"> (*)</span>
              )}
              :
            </b>
          </label>
        )}

        <input
          type={props.type ? props.type : 'text'}
          id={props.id}
          ref={ref}
          defaultValue={props.value}
          name={props.name}
          autoComplete={'off'}
          value={props.value}
          className={`${props.className} form-control border rounded`}
          readOnly={props.readonly}
          placeholder={
            props.placeholder == null ? undefined : caps(props.placeholder)
          }
          disabled={props.disabled}
          onKeyUp={(e) => {
            if (props.onChange != null) {
              const { value } = e.currentTarget
              //validor de rut
              if (props.isValidateRut) {
                //si es un rut valido true
                validRut(value)
                  ? props.onChange(rutFormat(value))
                  : props.onChange(rutClearFormat(value))
              }
            }
          }}
          onChange={(e) => {
            let { value } = e.currentTarget
            if (props.onlyNumber) {
              value = value.replace(ONLY_NUMBER, '')
              value = value.toString()
              e.target.value = value
            }
            if (props.onChange != null) {
              props.onChange(value)
            }
          }}
        />

        {props.errors && props.errors.length > 0 && (
          <div>
            {props.errors.map((e, i) => (
              <Fragment key={i}>
                <small className="text-danger" key={i}>
                  {e}
                </small>
                <br />
              </Fragment>
            ))}
          </div>
        )}

        {props.errorForm && (
          <div>
            <small className="text-danger">{props.errorForm.message}</small>
          </div>
        )}
      </Fragment>
    )
  }
)

import React, { useEffect, useState } from 'react'
import { Col, Form } from 'react-bootstrap'
import { Controller, useForm } from 'react-hook-form'
import * as yup from 'yup'
import { useCommonRoutes } from '../../../../Common/Hooks/useCommonRoutes'
import { useFullIntl } from '../../../../Common/Hooks/useFullIntl'
import { useShowAlertsRequest } from '../../../../Common/Hooks/useShowAlertsRequest'
import { ApiSelect } from '../../../../Components/Api/ApiSelect'
import { Buttons } from '../../../../Components/Common/Buttons'
import { Textbox } from '../../../../Components/Forms/Textbox'
import { IServicesResponse } from '../../../../Data/interfaces/Common/ServicesResponse'
import { IEquipment } from '../../../../Data/interfaces/Masters/Equipment/Equiment'
import {
  postEquipmentService,
  setEquipmentService,
} from '../../../../Services/Masters/Equipments/EquimentsServices'

const FormEquipment = ({
  dataInitial,
  isUpdate,
}: {
  dataInitial?: IEquipment
  isUpdate?: boolean
}) => {
  //hooks
  const showToast = useShowAlertsRequest()
  const { capitalize: caps } = useFullIntl()
  const { goBack } = useCommonRoutes()

  //states
  const [isSaving, setIsSaving] = useState<boolean>(false)

  //form
  const UsersFormSchema = yup.object().shape({
    name: yup.string().required(caps('validations:required')),
    sensor_tag: yup.string().required(caps('validations:required')),
    client_id: yup
      .string()
      .required(caps('validations:required'))
      .typeError(caps('validations:required')),
  })

  const { handleSubmit, register, errors, control, setValue } =
    useForm<IEquipment>({
      mode: 'onSubmit',
      submitFocusError: true,
      validationSchema: UsersFormSchema,
    })

  const onFinish = (responseService: IServicesResponse<undefined>) => {
    showToast({ msgs: responseService })
  }

  const onSubmit = async (data: IEquipment) => {
    setIsSaving(true)
    isUpdate && dataInitial && dataInitial.id
      ? await setEquipmentService({
        id: dataInitial.id,
        data,
        onFinish,
        onSuccess: () => goBack(),
      })
      : await postEquipmentService({
        data,
        onFinish,
        onSuccess: () => goBack(),
      })
    setIsSaving(false)
  }

  useEffect(() => {
    if (isUpdate && dataInitial !== undefined) {
      setValue([
        { name: dataInitial.name },
        { sensor_tag: dataInitial.sensor_tag },
        { client_id: dataInitial.client_id },
      ])
    }
  }, [dataInitial])

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Col sm={12} className={'pl-0 pr-0'}>
        <Col sm={3} className="pt-3">
          <Textbox
            label={`labels:name`}
            name={'name'}
            id={'name'}
            ref={register}
            errorForm={errors.name}
            placeholder={'placeholders.required'}
            isLabelRequired={true}
          />
        </Col>
        <Col sm={3} className="pt-3">
          <Textbox
            label={`labels:sensor_tag`}
            name={'sensor_tag'}
            id={'sensor_tag'}
            ref={register}
            errorForm={errors.sensor_tag}
            placeholder={'placeholders.required'}
            isLabelRequired={true}
          />
        </Col>
        <Col sm={3} className="pt-3">
          <Controller
            as={ApiSelect}
            control={control}
            name="client_id"
            placeholder="Seleccione ..."
            label="labels:client"
            isLabelRequired={true}
            isSelectFirtsOption={false}
            source={'client'}
            queryParams={{ withoutPagination: 1 }}
            selector={(option: any) => {
              return { label: option.name, value: option.id }
            }}
            errorForm={errors.client_id}
            onFinishLoad={() => {
              if (isUpdate) {
                setValue([{ client_id: dataInitial?.client_id }])
              }
            }}
          />
        </Col>
      </Col>
      <Buttons.Form isLoading={isSaving} />
    </Form>
  )
}

export default FormEquipment

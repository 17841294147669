import React from 'react'
import { ListaBase } from '../../../Components/views/ListaBase'
import { AlarmsEquipmentColumns } from '../../../Data/ColumnsTable/AlarmsEquipmentColumns'
import { IEquipment } from '../../../Data/interfaces/Masters/Equipment/Equiment'

function IndexAlarmsEquipments() {
  return (
    <>
      <ListaBase<IEquipment>
        title="titles:alarms_equipments"
        source={'equipments_alarms/list'}
        permission="equipment"
        columns={AlarmsEquipmentColumns}
        paginationServe={true}
      ></ListaBase>
    </>
  )
}

export default IndexAlarmsEquipments
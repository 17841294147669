import { useEffect, useState } from 'react'
import { AxiosError } from 'axios'

//utils
import { ax } from '../Utils/AxiosCustom'

//const
import { HOME_REDIRECT } from '../../Config/App'

//interface
import { IEmpresaSotfware } from '../../Data/interfaces/Config/Empresa'

export const useEmpresa = () => {
  const [datosEmpresa, setDatosEmpresa] = useState<IEmpresaSotfware>({
    logo: '',
    system_name: '',
    background_image: '',
  })

  useEffect(() => {
    async function feat() {
      await ax
        .get<IEmpresaSotfware>('company/show')
        .then((response) => {
          const dataEmpresa = response.data
          const industriaEmpresa = dataEmpresa.industria
            ? dataEmpresa.industria.toLowerCase().replace(/ /g, '_')
            : ''

          localStorage.setItem('logo', dataEmpresa.logo)
          localStorage.setItem('nombre_sistema', dataEmpresa.system_name)
          localStorage.setItem('imagen_principal', dataEmpresa.background_image)
          localStorage.setItem('industria', industriaEmpresa)

          setDatosEmpresa({
            logo: dataEmpresa.logo,
            system_name: dataEmpresa.system_name,
            background_image: dataEmpresa.background_image,
            industria: industriaEmpresa,
          })
        })
        .catch((error: AxiosError) => {
          const isRedirectToRegister =
            error.response?.data.message === 'client_no_found' &&
            error.response?.status === 403
          if (isRedirectToRegister) {
            window.location.replace(HOME_REDIRECT)
          }
        })
    }
    feat()
  }, [])

  return datosEmpresa
}

export const useEmpresaLabel = (label: string) => {
  return localStorage.getItem('industria')
    ? label + '.' + localStorage.getItem('industria')?.toLowerCase()
    : label
}

export const useEmpresaTipo = (tipo: string) => {
  return localStorage.getItem('industria') === tipo ? true : false
}

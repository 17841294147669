import { ApexOptions } from 'apexcharts';
import React, { useEffect, useState } from 'react'
import ReactApexChart from 'react-apexcharts'
import { Col } from 'react-bootstrap';
import { $m } from '../../../../Common/Utils/Reimports';
import { LoadingSpinner } from '../../../../Components/Common/LoadingSpinner';




function AccumulatedTonnageChart({ isLoading, data }: { isLoading: boolean, data: ApexAxisChartSeries }) {

  const [dataGraph, setDataGraph] = useState<ApexOptions>({
    chart: {
      type: "bar",
      width: "100%",
      zoom: {
        enabled: false
      }
    },
    colors: ['#002060'],
    grid: {
      show: true,
      borderColor: '#ccc',
      strokeDashArray: 2,
      position: "back",
      xaxis: {
        lines: {
          show: true
        }
      },
      yaxis: {
        lines: {
          show: true

        }
      },

    },
    xaxis: {
      type: 'category',
      labels: {
        formatter: function (val) {
          $m.locale('es')
          const date = $m(val, 'YYYY-MM', true)
          return date.isValid() ? date.format("MMM YY") : '';
        },
        style: {
          fontSize: '11px',
          fontFamily: 'Helvetica, Arial, sans-serif',
        },
      },

    },
    noData: {
      text: 'Sin datos de tonelaje acumulado',
      align: 'center',
      verticalAlign: 'middle',
      offsetX: 0,
      offsetY: 0,
      style: {
        color: undefined,
        fontSize: '14px',
        fontFamily: undefined
      }
    },
    dataLabels: {
      enabled: false
    },

    series: [],
  });

  useEffect(() => {
    setDataGraph({ ...dataGraph, series: data })
  }, [data])


  return isLoading ? (
    <Col>
      <LoadingSpinner />
    </Col>
  ) : (
    <div style={{ width: "100%" }} id="chart" >
      <ReactApexChart
        options={dataGraph}
        series={dataGraph.series}
        height={"99%"}
        width={"100%"}
        type="bar"
      />
    </div>

  )


}

export default AccumulatedTonnageChart
// Template built-ins
export const TOGGLE_NAV = 'TOGGLE_NAV'
export const TOGGLE_MENU = 'TOGGLE_MENU'
export const SET_FULLSCREEN = 'SET_FULLSCREEN'
export const SET_LAYOUT = 'SET_LAYOUT'
export const SET_LAYOUT_TYPE = 'SET_LAYOUT_TYPE'
export const RESET = 'RESET'
export const SET_HEADER_COLOR = 'SET_HEADER_COLOR'
export const TOGGLE_RTL = 'TOGGLE_RTL'
export const TOGGLE_FIXED_NAV = 'TOGGLE_FIXED_NAV'
export const TOGGLE_FIXED_HEADER = 'TOGGLE_FIXED_HEADER'
export const TOGGLE_BOX_MODE = 'TOGGLE_BOX_MODE'
export const NAV_CONTENT_LEAVE = 'NAV_CONTENT_LEAVE'
export const NAV_COLLAPSE_LEAVE = 'NAV_COLLAPSE_LEAVE'

// Whether to show the content on a fullscreen (no nav, no header) layout, or not
export const SET_CONTENT_ONLY = 'SET_CONTENT_ONLY'

import React, { useEffect, useState } from 'react'
import { Col } from 'react-bootstrap'
import { useNavigation } from '../../../Common/Hooks/useNavigation'
import { Buttons } from '../../../Components/Common/Buttons'
import { LoadingSpinner } from '../../../Components/Common/LoadingSpinner'
import { BaseContentView } from '../../../Components/views/BaseContentView'
import { IEquipment } from '../../../Data/interfaces/Masters/Equipment/Equiment'
import FormEquipment from './Components/FormEquipment'

const UpdateEquipment = () => {
  //hooks
  const { stateAs, goto } = useNavigation()
  const state: any = stateAs()

  //states
  const [equipment, setEquipment] = useState<IEquipment>()
  const [isLoadModule, setIsLoadModule] = useState<boolean>(true)

  //effects
  useEffect(() => {
    setIsLoadModule(true)
    if (state === undefined) {
      goto.absolute('base.equipment')
    } else {
      setEquipment(state.data as IEquipment)
    }
    setIsLoadModule(false)
  }, [])
  return (
    <BaseContentView title="Modificar equipo">
      {isLoadModule ? (
        <LoadingSpinner />
      ) : (
        <>
          <Col className="mb-4">
            <Buttons.Back />
          </Col>
          <Col className="mb-3 p-0">
            <FormEquipment dataInitial={equipment} isUpdate={true} />
          </Col>
        </>
      )}
    </BaseContentView>
  )
}

export default UpdateEquipment

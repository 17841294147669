import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useFullIntl } from '../../../../Common/Hooks/useFullIntl'
import { useShowAlertsRequest } from '../../../../Common/Hooks/useShowAlertsRequest'
import {
  TEXTBOX,
  ValidatedForm,
} from '../../../../Components/Forms/ValidatedForm'
import { IServicesResponse } from '../../../../Data/interfaces/Common/ServicesResponse'
import { postRolesService } from '../../../../Services/Config/roles/RolesServices'

const AgregarRolModal = ({
  modalAgregar,
  setReload,
}: {
  modalAgregar: any
  setReload: (reload: boolean) => void
}) => {
  //hooks
  const { capitalize: caps } = useFullIntl()
  const showToast = useShowAlertsRequest()

  //states
  const [resetAgregar, setResetAgregar] = useState(false)

  //handles
  function handleResetFormRole() {
    setResetAgregar(() => false)
  }

  async function handlePostRole(data: any) {
    const onSuccess = () => {
      setResetAgregar(true)
      setReload(true)
      modalAgregar.hide()
    }

    const onError = (error: IServicesResponse<undefined>) => {
      showToast({ msgs: error })
    }

    await postRolesService(data, onSuccess, onError)
  }

  return (
    <Modal
      show={modalAgregar.visible}
      onHide={modalAgregar.hide}
      onClose={() => setResetAgregar(() => true)}
    >
      <Modal.Header closeButton>
        <b>{caps('titles:add_role')}</b>
      </Modal.Header>
      <Modal.Body>
        <div className="modal-body">
          <ValidatedForm
            validations={{
              nombre: {
                presence: {
                  allowEmpty: false,
                  message: caps('validations:required'),
                },
              },
            }}
            fields={[
              {
                type: TEXTBOX,
                name: 'nombre',
                label: 'labels:name',
                reset: true,
                value: '',
                span: 12,
              },
            ]}
            reset={resetAgregar}
            onReset={handleResetFormRole}
            onSubmit={handlePostRole}
          />
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default AgregarRolModal

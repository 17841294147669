import React, { Fragment, useEffect, useRef, useState } from 'react'
import ReactDatePicker from 'react-datepicker'
import { useFullIntl } from '../../Common/Hooks/useFullIntl'
import { $m } from '../../Common/Utils/Reimports'
import { ApiSelect } from '../Api/ApiSelect'
import { JumpLabel } from '../Common/JumpLabel'

interface IProps {
  label?: string
  initialValueDate?: string
  initialValueTime?: string | null
  timeIntervals?: number
  includeTimes?: Date[]
  placeholder?: string
  includeDates?: Date[]
  disabled?: boolean
  errors?: string[]
  onChangeTime?: (date: string | undefined) => void
  onMonthChange?: (date: Date) => void
  onYearChange?: (date: Date) => void
  onSelect?: (date: Date) => void
  onCalendarOpen?: () => void
}

export const DateAndTimePicker = (props: IProps) => {
  const { capitalize: caps } = useFullIntl()

  const [startDate, setDateSelected] = useState<Date | null>(null)
  const [startTime, setTimeSelected] = useState<string | undefined>(undefined)
  const [caledarRef, setCalendarRef] = useState<ReactDatePicker | null>(null)

  const mapTimes = () => {
    const values =
      props.includeTimes === undefined
        ? []
        : props.includeTimes!.map((date) => {
          return $m(date).format('HH:mm:ss')
        })

    return values
  }

  const closeCalendar = () => {
    caledarRef && caledarRef.setOpen(false)
  }

  //effects
  useEffect(() => {
    props.initialValueDate !== undefined
      ? setDateSelected(new Date(props.initialValueDate))
      : setDateSelected(null)
  }, [props.initialValueDate])

  useEffect(() => {
    setTimeSelected(
      props.initialValueTime !== undefined && props.initialValueTime !== null
        ? $m(props.initialValueTime).format('HH:mm:ss')
        : undefined
    )
  }, [props.initialValueTime])

  return (
    <div className="msig-datepicker">
      <label>
        <b>{props.label && `${caps(props.label)}:`}</b>
      </label>
      <div className="input-group">
        <div className={'input-group-prepend msig-datepicker-icon'}>
          <i className="fas fa-calendar-alt input-group-text" />
        </div>

        <ReactDatePicker
          ref={(r) => { setCalendarRef(r) }}
          dateFormat="dd/MM/yyyy"
          showMonthDropdown
          useShortMonthInDropdown
          locale={'es'}
          className="form-control msig-datepicker-input"
          selected={startDate}
          includeDates={props.includeDates}
          placeholderText={props.placeholder}
          onChange={(date: Date | null) => {
            setDateSelected(date)
          }}
          onSelect={props.onSelect}
          onMonthChange={(date) => {
            props.onMonthChange && props.onMonthChange(date)
            closeCalendar()
          }}
          onYearChange={(date) => {
            props.onYearChange && props.onYearChange(date)
            closeCalendar()
          }}
          onCalendarOpen={props.onCalendarOpen}
          autoComplete="off"
          disabled={props.disabled}
          showYearDropdown
        />
        <div style={{ width: '100%' }} className="pl-2">
          <ApiSelect<string>
            name="time_report"
            styleContainer={{ color: '#000' }}
            isDisabled={props.disabled}
            value={startTime}
            className="form-control"
            onChange={(timeSelected) => {
              setTimeSelected(startDate !== null ? timeSelected : undefined)
              const timeUsed = $m(timeSelected, 'HH:mm:ss')
              const dateUsed = startDate === null ? undefined : $m(startDate).set({
                hour: timeUsed.get('hour'),
                minute: timeUsed.get('minute'),
                second: timeUsed.get('second'),
              }).format('YYYY-MM-DD HH:mm:ss')
              props.onChangeTime !== undefined && props.onChangeTime(dateUsed)
            }}
            source={mapTimes()}
            selector={(value) => {
              return { label: value, value: value }
            }}
            placeholder="hora"
          />
        </div>
      </div>

      {props.errors && props.errors.length > 0 && (
        <div>
          {props.errors.map((e, i) => {
            return (
              <Fragment key={i}>
                <small className="text-danger" key={i}>
                  {e}
                </small>
                <br />
              </Fragment>
            )
          })}
        </div>
      )}
    </div>
  )
}

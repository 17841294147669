import { IDataTableColumn } from 'react-data-table-component'
import { IntlShape } from 'react-intl'

//utils
import { Utils } from '../../Common/Utils/Utils'

//interface
import { IUsuario } from '../interfaces/Config/Usuario'

export const UsuarioColumns: (
  intl: IntlShape
) => IDataTableColumn<IUsuario>[] = (intl) => {
  const header = Utils.capitalize(intl)
  return [
    { selector: 'username', name: header('labels:username') },
    {
      selector: 'nombre',
      name: header('labels:names'),
      format: (usuario) => usuario.name,
    },
    {
      selector: 'rol',
      name: header('labels:role'),
      format: (usuario) => usuario.role,
      width: '20%',
    },
    {
      selector: 'status',
      width: '15%',
      center: true,
      name: header('labels:status'),
      format: (usuario) =>
        usuario.status !== undefined
          ? header(usuario.status ? 'labels:active' : 'labels:inactive')
          : header('labels:not_applicable'),
    },
  ]
}

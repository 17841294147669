import { LocalizedColumnsCallback } from '../../Common/Utils/LocalizedColumnsCallback'
import { Utils } from '../../Common/Utils/Utils'
import { IClient } from '../interfaces/Masters/Clients/Client'

export const ClientsColumns: LocalizedColumnsCallback<IClient> = (intl) => {
  const caps = Utils.capitalize(intl)
  return [
    { selector: 'name', name: caps('labels:name'), width: '80%' },
    {
      selector: 'assigned_equipments',
      name: caps('labels:assigned_equipments'),
      width: '20%',
      center: true,
    },
  ]
}

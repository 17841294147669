import axios, { AxiosError } from 'axios'

//components
import { AppStore } from '../../Store/AppStore'
import {
  getLogout,
  resetDashboard,
  setLoading,
} from '../../Store/Dashboard/DashboardActionCreators'

//const
import { API_URL } from '../../Config/Api'

axios.defaults.withCredentials = false

export const ax = axios.create({ baseURL: API_URL })

export const axf = axios.create({ baseURL: API_URL })

ax.interceptors.request.use((request) => {
  const token = localStorage.getItem('api-token')

  if (token && request.headers) {
    request.headers['Authorization'] = `Bearer ${token}`
    request.headers['Access-Control-Max-Age'] = 600
  }
  return request
})

ax.interceptors.response.use(
  (response) => {
    return response
  },
  async (e: AxiosError) => {
    if (e.response != null) {
      if (e.response.status === 401) {
        AppStore.dispatch(setLoading(true))

        localStorage.removeItem('api-token')
        localStorage.clear()
        sessionStorage.clear()

        AppStore.dispatch(getLogout())
        AppStore.dispatch(resetDashboard())

        AppStore.dispatch(setLoading(false))
      }
      return Promise.reject(e)
    }
  }
)

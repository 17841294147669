import React from 'react'
import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'

//internals
import { login } from '../../Store/Dashboard/DashboardActionCreators'
import { useFullIntl } from '../../Common/Hooks/useFullIntl'
import { BaseLoginComponent } from './components/BaseLoginComponent'
import {
  TEXTBOX,
  PASSWORD,
  ValidatedForm,
} from '../../Components/Forms/ValidatedForm'

//assets
import './../../Assets/scss/style.scss'

export const Login = () => {
  const { capitalize: caps } = useFullIntl()
  const dispatch = useDispatch()
  const doLogin = useCallback(
    (user: string, password: string) => dispatch(login(user, password)),
    [dispatch]
  )

  function onSubmit(e: { nombre_usuario: string; clave: string }) {
    doLogin(e.nombre_usuario, e.clave)
  }

  return (
    <BaseLoginComponent>
      <ValidatedForm
        submitIcon=""
        submitLabel="labels:links.signin"
        onSubmit={onSubmit}
        validations={{
          nombre_usuario: {
            presence: {
              allowEmpty: false,
              message: caps('validations:required', {
                field: caps('labels:inputs.username'),
              }),
            },
          },
          clave: {
            presence: {
              allowEmpty: false,
              message: caps('validations:required', {
                field: caps('labels:inputs.password'),
              }),
            },
          },
        }}
        fields={[
          {
            type: TEXTBOX,
            name: 'nombre_usuario',
            placeholder: 'labels:inputs.user_or_email',
          },
          {
            type: PASSWORD,
            name: 'clave',
            placeholder: 'labels:inputs.password',
          },
        ]}
      />
      <Link to="/restablecer_contraseña">
        {caps('labels:links.forgot_my_password')}
      </Link>
    </BaseLoginComponent>
  )
}

import React from 'react'
import { ListaBase } from '../../../Components/views/ListaBase'
import { EquipmentsColumns } from '../../../Data/ColumnsTable/EquipmentColumns'
import { IEquipment } from '../../../Data/interfaces/Masters/Equipment/Equiment'

const IndexEquipments = () => {
  return (
    <>
      <ListaBase<IEquipment>
        title="titles:equipments"
        source={'equipment/list'}
        permission="equipment"
        columns={EquipmentsColumns}
        paginationServe={true}
      ></ListaBase>
    </>
  )
}
export default IndexEquipments

import React, { Suspense, useEffect } from 'react'
import { IntlProvider } from 'react-intl'
import {
  DefaultToast,
  ToastProps,
  ToastProvider,
} from 'react-toast-notifications'
import DocumentTitle from 'react-document-title'
import { Route, Switch } from 'react-router-dom'

//views
import { AdminLayout } from './Template/AdminLayout/AdminLayout'
import { ErrorView } from './Components/Common/Notifications'
import { RestablecerContrasena } from './Pages/Auth/RestablecerContrasena'
import { CreateContrasena } from './Pages/Auth/CreateContrasena'
import { Login } from './Pages/Auth/Login'

//hooks
import { useDashboard } from './Common/Hooks/useDashboard'
import { useCurrenteNameModule } from './Common/Hooks/useCurrenteNameModule'
import { Loader } from './Components/views/Loader'
import { LoadScreen } from './Components/views/LoadScreen'

const CustomToast: React.FC<ToastProps> = ({ children, ...props }) => (
  <DefaultToast {...props}>
    <div>{children}</div>
  </DefaultToast>
)

export const App = () => {
  const { locale, messages, authenticated, loading, forwardUserToken } =
    useDashboard()
  const nameCurrentModule = useCurrenteNameModule()

  //const { isLatestVersion, emptyCacheStorage } = useClearCacheCtx();

  useEffect(() => {
    forwardUserToken()
  }, [forwardUserToken])

  const onIntlErrorCallback = () => {
    /* TODO: Handle localization error */
  }

  return (
    <div>
      <IntlProvider
        locale={locale}
        messages={messages}
        onError={onIntlErrorCallback}
      >
        <ToastProvider
          autoDismissTimeout={6000}
          placement="top-right"
          components={{ Toast: CustomToast }}
        >
          <div
            style={
              loading
                ? { width: '100vw', height: '100vh', overflow: 'hidden' }
                : {}
            }
          >
            {/* TODO: Suspense is not implemented in a right way, 
              so you most likely won't ever see the Loader component.
              Either remove suspense or the upper conditional loading component */}
            {loading && <LoadScreen background="var(--dark)" />}
            <Suspense fallback={<Loader />}>
              {authenticated ? (
                <DocumentTitle title={nameCurrentModule}>
                  <AdminLayout />
                </DocumentTitle>
              ) : (
                <Switch>
                  <Route
                    path="/crear_contraseña/token=:token&email=:email"
                    component={CreateContrasena}
                  />
                  <Route
                    path="/restablecer_contraseña"
                    component={RestablecerContrasena}
                  />
                  <Login />
                </Switch>
              )}
            </Suspense>
            <ErrorView />
          </div>
        </ToastProvider>
      </IntlProvider>
    </div>
  )
}

//activar pusher
const DEFAULT_PUSHER_APP_ON = false
export const PUSHER_APP_ON: boolean = process.env.REACT_APP_PUSHER_ON
  ? process.env.REACT_APP_PUSHER_ON.toLowerCase() === 'true'
  : DEFAULT_PUSHER_APP_ON

//credenciales pusher
export const PUSHER_APP_KEY =
  process.env.REACT_APP_PUSHER_KEY || '66556cd67fb70e4f4030'
export const PUSHER_APP_CLUSTER = process.env.REACT_APP_PUSHER_CLUSTER || 'us2'

//solo para configuracion nativa con laravelwebsockets
export const PUSHER_APP_PORT = 6001

import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { BaseContentView } from '../../../Components/views/BaseContentView'
import HistoricalMeditationChart from './components/HistoricalMeditationChart'
import UsageReportBackground from '../../../Assets/images/UsageReportBackground.png'
import LogoMagotteaux from '../../../Assets/images/LogoMagotteaux.png'
import { IUsageReport } from './interfaces'
import { useShowAlertsRequest } from '../../../Common/Hooks/useShowAlertsRequest'
import { getDataUsageReportParams, getDataUsageReportService, getDatesInReportService } from '../../../Services/ReportesServices/UsageReportServices'
import { $m, $u } from '../../../Common/Utils/Reimports'
import { DateAndTimePicker } from '../../../Components/Forms/DateAndTimePicker'
import { ApiSelect } from '../../../Components/Api/ApiSelect'
import { IEquimentData } from '../../../Data/interfaces/Reports/UsageReports'
import BateryGauge from './components/BateryGauge'
import BoxValueGauge from './components/BoxValueGauge'
import CotainerGadge from './components/CotainerGadge'
import { GoogleMapMarker } from './components/GoogleMapMarker'
import LevelMeasurementsChart from './components/LevelMeasurementsChart'
import AccumulatedTonnageChart from './components/AccumulatedTonnageChart'
import { NumberUtils } from '../../../Common/Utils/NumberUtils'

function UsageReport_v2() {

  const showToast = useShowAlertsRequest()

  //states
  const [dataGrapUsage, setDataGrapUsage] = useState<IUsageReport>({
    nominals: [],
    coordinates: [],
    output: null,
    measurement_history: [],
    accumulated_tonnage: []
  })

  const [dataUsageReportParams, setDataUsageReportParams] =
    useState<getDataUsageReportParams>({
      equipment_id: undefined,
      coordinate: 1,
      nominal: 1,
    })

  const [isLoadingData, setIsLoadingData] = useState<boolean>(true)
  const [errorMessageModule, setErrorMessageModule] = useState<string | null>(
    null
  )
  const [dateSelectedReport, setDateSelectedReport] = useState<
    string | undefined
  >()
  const [timeSelectedReport, setTimeSelectedReport] = useState<string | null>()

  const [dateMounthReport, setDateMounthReport] = useState<string | undefined>(
    $m().startOf('month').format('yyyy-MM-DD').toString()
  )

  const [includeDatesReport, setIncludeDates] = useState<Date[]>([])
  const [includeTimesReport, setIncludeTimes] = useState<Date[]>([])
  const [datesTimeMonthReport, setDatesTimeMonthReport] = useState<
    Record<string, string[]>
  >({})

  //handles
  const resetGraph = () => {
    setDataGrapUsage((state) =>
      $u(state, {
        $merge: {
          nominals: [],
          coordinates: [],
          output: undefined,
          measurement_history: [],
          accumulated_tonnage: []
        },
      })
    )
  }
  const getDataUsageReport = async () => {
    setIsLoadingData(true)
    const response = await getDataUsageReportService(dataUsageReportParams)
    if (response.appearanceMessage == 'success') {
      const errorMsgModule: string | null =
        (response.responseMessage as IUsageReport).output === undefined
          ? 'No se encontraron datos del equipo'
          : null
      setErrorMessageModule(errorMsgModule)
      setDataGrapUsage((state) =>
        $u(state, {
          $merge: {
            nominals: response.responseMessage
              ? (response.responseMessage as IUsageReport).nominals
              : [],
            coordinates: response.responseMessage
              ? (response.responseMessage as IUsageReport).coordinates
              : [],
            output: response.responseMessage
              ? (response.responseMessage as IUsageReport).output
              : undefined,
            measurement_history: response.responseMessage
              ? (response.responseMessage as IUsageReport).measurement_history
              : [],
            accumulated_tonnage: response.responseMessage
              ? (response.responseMessage as IUsageReport).accumulated_tonnage
              : []
          },
        })
      )
    } else {
      setErrorMessageModule(response.responseMessage!.toString())
      resetGraph()
      showToast({ msgs: response })
    }
    setIsLoadingData(false)
  }

  /**
   * obtener las fechas con datos para el reporte de los equipos
   * @param selectedDate seleccionar ultima fecha de la data (primera carga)
   */
  const getDatesInReport = async () => {
    if (
      dataUsageReportParams.equipment_id === undefined ||
      dateMounthReport === undefined
    ) {
      return
    }
    setIsLoadingData(true)
    const response = await getDatesInReportService({
      idEquipment: dataUsageReportParams.equipment_id,
      month: dateMounthReport,
    })
    if (response.appearanceMessage == 'success') {
      let timesInReport: Date[] = []
      let datesInReport: Date[] = []
      const datesTimeMonth: Record<string, string[]> =
        response.responseMessage as Record<string, string[]>
      const dataResponseAsArray = Object.keys(datesTimeMonth)

      if (dataResponseAsArray.length > 0) {
        datesInReport = dataResponseAsArray.map((value) => {
          return new Date(`${value} 00:00`)
        })
        const lastDate: string = dataResponseAsArray[0]
        const lastTime: string = datesTimeMonth[lastDate][0]

        setDateSelectedReport(`${lastDate} ${lastTime}`)
        setTimeSelectedReport(`${lastDate} ${lastTime}`)
        setDataUsageReportParams((state) =>
          $u(state, {
            $merge: { date: `${lastDate} ${lastTime}` },
          })
        )
        timesInReport = datesTimeMonth[lastDate].map((valueTime) => {
          return $m(`${lastDate} ${valueTime}`, 'DD-MM-YYYY HH:mm:ss').toDate()
        })
        setIncludeTimes(timesInReport)
      } else {
        setErrorMessageModule('No hay registro en el mes')
        setDateSelectedReport(`${$m(`${dateMounthReport}`, 'YYYY-MM-DD HH:mm:ss')}`)
        setDataUsageReportParams((state) =>
          $u(state, {
            $merge: { date: undefined },
          })
        )
        setIncludeTimes([])
        resetGraph()
        setIsLoadingData(false)
      }
      setDatesTimeMonthReport(datesTimeMonth)
      setIncludeDates(datesInReport)
    } else {
      setErrorMessageModule('Ha ocurrido un error')
      showToast({ msgs: response })
    }
  }

  const updateIncludeTimes = (dateSelected: string) => {
    const timesInReport = datesTimeMonthReport[dateSelected].map(
      (valueTime) => {
        return new Date(`${dateSelected} ${valueTime}`)
      }
    )
    return timesInReport
  }

  useEffect(() => {
    dataUsageReportParams.equipment_id !== undefined && getDatesInReport()
  }, [dataUsageReportParams.equipment_id, dateMounthReport])

  useEffect(() => {
    if (dataUsageReportParams.equipment_id !== undefined
      && dataUsageReportParams.date !== undefined) {
      getDataUsageReport()
    }
  }, [dataUsageReportParams.date])

  return (
    <BaseContentView classStyle='pt-0 p-0'>
      <Col sm={12}
        style={{
          backgroundImage: `url(${UsageReportBackground})`,
          backgroundSize: '100%',
          minHeight: '93vh',
          backgroundRepeat: 'no-repeat'
        }}
      >
        <Col sm={12} className="pt-3" >
          <Row>
            <Col sm={{ order: 'last', span: 4 }} className="pb-4 pb-sm-0">
              <Col sm={12} >
                <img
                  id="main-logo"
                  src={LogoMagotteaux}
                  alt="logo-company"
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'contain',
                  }}
                ></img>
              </Col>
            </Col>
            <Col sm={8} className="py-4" style={{ background: '#002060', borderRadius: '0.50rem', color: '#fff' }} >
              <Col sm={12} className='p-0 d-flex flex-column'>
                <Col sm={12} className='d-flex align-items-center'>
                  <h4 style={{ color: '#fff' }}>
                    Cliente: {dataUsageReportParams.client !== undefined && dataUsageReportParams.client}
                  </h4>
                </Col>
                <Col sm={12} className='p-0'>
                  <Col md={7} className="pt-2">
                    <ApiSelect<IEquimentData>
                      label="labels:equipment"
                      name="equipment"
                      queryParams={{ withClients: true }}
                      styleContainer={{ color: '#000' }}
                      isDisabled={isLoadingData}
                      value={dataUsageReportParams.equipment_id}
                      valueInObject={true}
                      onChange={(equipo) => {
                        if (equipo && equipo.value) {
                          resetGraph()
                          setDataUsageReportParams((state) =>
                            $u(state, {
                              $merge: {
                                equipment_id: equipo.value,
                                client: equipo.client,
                                date: undefined
                              },
                            })
                          )
                        }

                      }}
                      onFinishLoad={(optionsSize: number) => {
                        if (optionsSize === 0) {
                          setIsLoadingData(false)
                          setErrorMessageModule('No posee equipos asignados')
                        }
                      }}
                      source={'equipment'}
                      selector={(equipo) => {
                        return {
                          label: `${equipo.name} - ${equipo.params_equipment.bin} 
                            - TAMAÑO BOLAS: ${equipo.params_equipment.ball_size}`,
                          value: equipo.id.toString(),
                          client: equipo.clients.length > 0 ? equipo.clients[0].name : undefined
                        }
                      }}
                    />
                  </Col>
                  <Col md={5} className="pt-2" >
                    <DateAndTimePicker
                      label="labels:date_and_time"
                      placeholder="Fecha"
                      initialValueDate={dateSelectedReport}
                      initialValueTime={timeSelectedReport}
                      includeTimes={includeTimesReport}
                      includeDates={includeDatesReport}
                      disabled={isLoadingData}
                      onSelect={(date) => {
                        resetGraph()
                        const times = updateIncludeTimes(
                          $m(date).format('yyyy-MM-DD').toString()
                        )
                        const dateSelected = $m(times[0]).format('YYYY-MM-DD HH:mm:ss')
                        setTimeSelectedReport(dateSelected)
                        setDataUsageReportParams((state) =>
                          $u(state, {
                            $merge: { date: dateSelected },
                          })
                        )
                        setIncludeTimes(times)
                      }}
                      onMonthChange={(date: Date | null) => {
                        resetGraph()
                        date !== null &&
                          setDateMounthReport(
                            $m(date).startOf('month').format('yyyy-MM-DD').toString()
                          )

                      }}
                      onYearChange={(date: Date | null) => {
                        resetGraph()
                        date !== null &&
                          setDateMounthReport(
                            $m(date).startOf('month').format('yyyy-MM-DD').toString()
                          )
                      }}
                      onChangeTime={(date) => {
                        resetGraph()
                        setDataUsageReportParams((state) =>
                          $u(state, {
                            $merge: { date: date },
                          })
                        )
                        setTimeSelectedReport(date)
                      }}

                    />
                  </Col>
                </Col>
              </Col>
            </Col>

          </Row>
        </Col>
        <Col sm={12} style={{ minHeight: '72vh' }} className="d-sm-flex p-0" >

          {/* primera seccion de graficos  */}
          <Col sm={5} style={{ minHeight: '72vh' }} className="p-0">

            {/* seccion de nivel de buzon */}
            <Col style={{ minHeight: '47vh' }} className="d-flex flex-column py-3 px-0 pr-sm-2">
              <CotainerGadge icon="fas fa-chart-area" title="Nivel Buzón" >
                <LevelMeasurementsChart
                  coordinates={dataGrapUsage.coordinates}
                  nominal={dataGrapUsage.nominals}
                  isLoading={isLoadingData}
                />
              </CotainerGadge>
            </Col>

            {/* seccion de mini cajas */}
            <Col style={{ minHeight: '18vh', gap: '1rem' }} className="d-flex justify-content-between pb-2 px-0  pr-sm-2">
              <BoxValueGauge title='Ocupación' unit='%' value={dataGrapUsage.output?.occupation
                ? NumberUtils.fixedDimanic(dataGrapUsage.output?.occupation * 100, 2) : undefined}
                enableAlarm={true}
                isLoading={isLoadingData}
              />
              <BoxValueGauge title='Masa' unit='ton' value={dataGrapUsage.output?.ball_load} isLoading={isLoadingData} />
              <BoxValueGauge title='Volumen' unit='m' supUnit={'3'} value={dataGrapUsage.output?.ball_volume} isLoading={isLoadingData} />
            </Col>

            {/* seccion de bateria */}
            <Col style={{ minHeight: '7vh' }} className="d-flex align-items-center px-0 pr-sm-2">
              <BateryGauge value={
                dataGrapUsage.output?.battery_percentage
                  ? NumberUtils.fixedDimanic(dataGrapUsage.output?.battery_percentage * 100, 2) : 0
              } />
            </Col>
          </Col>

          {/* segunda seccion de graficos */}
          <Col sm={7} style={{ minHeight: '72vh' }} className="px-0">
            {/* seccion de historial de mediciones */}
            <Col style={{ minHeight: '39vh' }} className="d-flex flex-column py-3 px-0 pl-sm-2">
              <CotainerGadge icon="fas fa-chart-bar" title="Historial de mediciones" >
                <HistoricalMeditationChart data={dataGrapUsage.measurement_history ?? []} isLoading={isLoadingData} />
              </CotainerGadge>
            </Col>

            <Col style={{ minHeight: '33vh' }}
              className="d-flex flex-column flex-sm-row justify-content-between p-0 pl-sm-2"
            >
              {/* seccion de tonelaje acumulado */}
              <Col sm={6} className="p-0 mr-0 mr-2 d-flex"
                style={{
                  minHeight: '25vh',
                  flex: '1 1 auto',
                  flexBasis: 0,
                  minWidth: 0,
                }
                }>
                <CotainerGadge icon="fas fa-chart-bar" title="Tonelaje acumulado mensual" >
                  <AccumulatedTonnageChart data={dataGrapUsage.accumulated_tonnage ?? []} isLoading={isLoadingData} />
                </CotainerGadge>

              </Col>

              {/* seccion de ubicacion del dispositivo */}
              <Col sm={6} className="p-0 pt-3 pt-sm-0 ml-0 ml-sm-2 d-flex"
                style={{
                  minHeight: '25vh',
                  flex: '1 1 auto',
                  flexBasis: 0,
                  minWidth: 0,
                }}
              >
                <CotainerGadge icon="fas fa-map-marked-alt" title="Ubicación del dispositivo">
                  <GoogleMapMarker
                    lng={dataGrapUsage.output?.gps_logitude}
                    lat={dataGrapUsage.output?.gps_latitude}
                    client={dataGrapUsage.output?.client}
                    isLoading={isLoadingData}
                  />
                </CotainerGadge>
              </Col>

            </Col>
          </Col>
        </Col>

      </Col>
    </BaseContentView>

  )
}

export default UsageReport_v2
import { AxiosError, AxiosResponse } from 'axios'
import { ax } from '../../../Common/Utils/AxiosCustom'
import { HttpUtils } from '../../../Common/Utils/HttpUtils'
import { IServicesResponse } from '../../../Data/interfaces/Common/ServicesResponse'
import { IEmpresa } from '../../../Data/interfaces/Config/Empresa'

export const getCompanyProfileService = async () => {
  const serviceResponse: IServicesResponse<IEmpresa> = {
    appearanceMessage: 'error',
  }
  await ax
    .get<IEmpresa>('company')
    .then((res: AxiosResponse) => {
      serviceResponse.appearanceMessage = 'success'
      serviceResponse.responseMessage = res.data
    })
    .catch((error: AxiosError) => {
      serviceResponse.responseMessage = HttpUtils.localizedErrorMsg(error)
    })

  return serviceResponse
}

export const setCompanyProfileService = async (formData: FormData) => {
  const serviceResponse: IServicesResponse<IEmpresa> = {
    appearanceMessage: 'error',
  }
  const headers = { headers: { 'Content-Type': 'multipart/form-data' } }

  await ax
    .post('company/update', formData, headers)
    .then((res: AxiosResponse) => {
      serviceResponse.appearanceMessage = 'success'
      serviceResponse.responseMessage = res.data
    })
    .catch((error: AxiosError) => {
      serviceResponse.responseMessage = HttpUtils.localizedErrorMsg(error)
    })
  return serviceResponse
}

import React, { useEffect, useState } from 'react'
import { Col, Form } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { useCommonRoutes } from '../../../../Common/Hooks/useCommonRoutes'
import { useFullIntl } from '../../../../Common/Hooks/useFullIntl'
import { useShowAlertsRequest } from '../../../../Common/Hooks/useShowAlertsRequest'
import { Buttons } from '../../../../Components/Common/Buttons'
import { Textbox } from '../../../../Components/Forms/Textbox'
import { IServicesResponse } from '../../../../Data/interfaces/Common/ServicesResponse'
import { IClient } from '../../../../Data/interfaces/Masters/Clients/Client'
import {
  postClientService,
  setClientService,
} from '../../../../Services/Masters/Clients/ClientsService'

const FormClient = ({
  dataInitial,
  isUpdate,
}: {
  dataInitial?: IClient
  isUpdate?: boolean
}) => {
  //hooks
  const showToast = useShowAlertsRequest()
  const { capitalize: caps } = useFullIntl()
  const { goBack } = useCommonRoutes()

  //states
  const [isSaving, setIsSaving] = useState<boolean>(false)

  //form
  const UsersFormSchema = yup.object().shape({
    name: yup.string().required(caps('validations:required')),
  })

  const { handleSubmit, register, errors, setValue } = useForm<IClient>({
    mode: 'onSubmit',
    submitFocusError: true,
    validationSchema: UsersFormSchema,
  })

  const onFinish = (responseService: IServicesResponse<undefined>) => {
    showToast({ msgs: responseService })
  }

  const onSubmit = async (data: IClient) => {
    setIsSaving(true)
    isUpdate && dataInitial && dataInitial.id
      ? await setClientService({
          id: dataInitial.id,
          data,
          onFinish,
          onSuccess: () => goBack(),
        })
      : await postClientService({ data, onFinish, onSuccess: () => goBack() })
    setIsSaving(false)
  }

  useEffect(() => {
    if (isUpdate && dataInitial !== undefined) {
      setValue([{ name: dataInitial.name }])
    }
  }, [dataInitial])

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Col sm={12} className={'pl-0 pr-0'}>
        <Col sm={3} className="pt-3">
          <Textbox
            label={`labels:name`}
            name={'name'}
            id={'name'}
            ref={register}
            errorForm={errors.name}
            placeholder={'placeholders.required'}
            isLabelRequired={true}
          />
        </Col>
      </Col>
      <Buttons.Form isLoading={isSaving} />
    </Form>
  )
}

export default FormClient

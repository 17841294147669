import { AxiosError, AxiosResponse } from 'axios'
import { ax } from '../../../Common/Utils/AxiosCustom'
import { HttpUtils } from '../../../Common/Utils/HttpUtils'
import { IServicesResponse } from '../../../Data/interfaces/Common/ServicesResponse'
import { IUsuarioForm } from '../../../Data/interfaces/Config/Usuario'

const serviceResponse: IServicesResponse<IUsuarioForm> = {
  appearanceMessage: 'error',
}

export const postUserService = async (data: IUsuarioForm) => {
  await ax
    .post('user', data)
    .then((res: AxiosResponse) => {
      serviceResponse.appearanceMessage = 'success'
      serviceResponse.responseMessage = res.data
    })
    .catch((error: AxiosError) => {
      serviceResponse.appearanceMessage = 'error'
      serviceResponse.responseMessage = HttpUtils.localizedErrorMsg(error)
    })

  return serviceResponse
}

export const setUserService = async (
  usernameEdit: string,
  data: IUsuarioForm
) => {
  await ax
    .patch(`user/update/${usernameEdit}`, data)
    .then((res: AxiosResponse) => {
      serviceResponse.appearanceMessage = 'success'
      serviceResponse.responseMessage = res.data
    })
    .catch((error: AxiosError) => {
      serviceResponse.appearanceMessage = 'error'

      serviceResponse.responseMessage = HttpUtils.localizedErrorMsg(error)
    })

  return serviceResponse
}

export const getSingleUserService = async (username: string) => {
  await ax
    .get(`user/show/${username}`, { params: { equipments: 1 } })
    .then((res: AxiosResponse) => {
      serviceResponse.appearanceMessage = 'success'
      serviceResponse.responseMessage = res.data
    })
    .catch((error: AxiosError) => {
      serviceResponse.appearanceMessage = 'error'
      serviceResponse.responseMessage = HttpUtils.localizedErrorMsg(error)
    })

  return serviceResponse
}

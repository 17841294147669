import React from 'react'
import { Col } from 'react-bootstrap'
import { Buttons } from '../../../Components/Common/Buttons'
import { BaseContentView } from '../../../Components/views/BaseContentView'
import FormAlarmEquipment from './Components/FormAlarmEquipment'

function AddAlarmsEquipment() {
  return (
    <BaseContentView title="Agregar alarma de equipo">
      <Col className="mb-4">
        <Buttons.Back />
      </Col>
      <Col className="mb-3 p-0">
        <FormAlarmEquipment />
      </Col>
    </BaseContentView>
  )
}

export default AddAlarmsEquipment
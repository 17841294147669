import React from 'react'
import { Col } from 'react-bootstrap'
import HomeDashboard from '../../Assets/images/HomeDashboard.png'

export const DashboardHome = () => {
  return (
    <Col className={`p-0`} style={{ height: '93vh' }}>
      {/* <img
        alt='home dashboard'
        src={HomeDashboard}
        style={{ width: '100%', objectFit: 'contain', maxHeight: '93vh' }}
      /> */}
    </Col>
  )
}

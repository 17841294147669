import { AxiosError, AxiosResponse } from 'axios'
import { ax } from '../../../Common/Utils/AxiosCustom'
import { HttpUtils } from '../../../Common/Utils/HttpUtils'
import { IServicesResponse } from '../../../Data/interfaces/Common/ServicesResponse'

export const postClientService = async ({
  data,
  onFinish,
  onSuccess,
}: {
  data: any
  onSuccess?: () => void
  onFinish: (error: IServicesResponse<undefined>) => void
}) => {
  const response: IServicesResponse<undefined> = {
    appearanceMessage: 'error',
  }
  await ax
    .post('client', data)
    .then((res: AxiosResponse) => {
      response.appearanceMessage = 'success'
      response.responseMessage = res.data
      onSuccess && onSuccess()
    })
    .catch((error: AxiosError) => {
      response.appearanceMessage = 'error'
      response.responseMessage = HttpUtils.localizedErrorMsg(error)
    })
    .finally(() => {
      onFinish(response)
    })
}

export const setClientService = async ({
  id,
  data,
  onFinish,
  onSuccess,
}: {
  id: number
  data: any
  onSuccess?: () => void
  onFinish: (error: IServicesResponse<undefined>) => void
}) => {
  const response: IServicesResponse<undefined> = {
    appearanceMessage: 'error',
  }
  await ax
    .patch(`client/${id}`, data)
    .then((res: AxiosResponse) => {
      response.appearanceMessage = 'success'
      response.responseMessage = res.data
      onSuccess && onSuccess()
    })
    .catch((error: AxiosError) => {
      response.appearanceMessage = 'error'
      response.responseMessage = HttpUtils.localizedErrorMsg(error)
    })
    .finally(() => {
      onFinish(response)
    })
}

import React from 'react'
import { Col } from 'react-bootstrap'
import { Buttons } from '../../../Components/Common/Buttons'
import { BaseContentView } from '../../../Components/views/BaseContentView'
import FormClient from './Components/FormClient'

const AddClient = () => {
  return (
    <BaseContentView title="Agregar cliente">
      <Col className="mb-4">
        <Buttons.Back />
      </Col>
      <Col className="mb-3 p-0">
        <FormClient />
      </Col>
    </BaseContentView>
  )
}

export default AddClient

import { ApexOptions } from 'apexcharts';
import React, { useEffect, useState } from 'react'
import ReactApexChart from 'react-apexcharts'
import { Col } from 'react-bootstrap';
import { $u } from '../../../../Common/Utils/Reimports';
import { LoadingSpinner } from '../../../../Components/Common/LoadingSpinner';

const initialOptionsGraph = [
  {
    name: "coordinates",
    type: "area",
    data: [],
  },
  {
    name: 'nominal',
    type: 'line',
    data: [],
  }
]


interface IDataGrapUsage {
  nominal: {
    x: any;
    y: any;
  }[],
  coordinates: {
    x: any;
    y: any;
  }[],
  isLoading?: boolean
}
function LevelMeasurementsChart({ nominal, coordinates, isLoading = false }: IDataGrapUsage) {
  //const
  const optionGraph: ApexOptions = {
    fill: {
      type: "solid",
      colors: ['#ff873c', '#002060'],
    },
    chart: {
      zoom: {
        enabled: false
      },
      width: '100%'
    },
    dataLabels: {
      enabled: false
    },
    grid: {
      show: true,
      borderColor: '#ccc',
      strokeDashArray: 2,
      position: "back",
      xaxis: {
        lines: {
          show: false
        }
      },
      yaxis: {
        lines: {
          show: true

        }
      },

    },
    stroke: {
      curve: 'straight',
      width: 2,
      colors: ['#ff5400', '#002060'],

    },
    xaxis: {
      type: 'numeric',
      labels: {
        show: false
      },
      axisBorder: {
        show: false,
      },
    },

    yaxis: {
      labels: {
        show: true
      },
      decimalsInFloat: 0,
      title: {
        text: "mm",
        offsetX: 0,
        offsetY: 0,
        style: {
          color: undefined,
          fontSize: '12px',
          fontFamily: 'Helvetica, Arial, sans-serif',
          fontWeight: 600,
          cssClass: 'apexcharts-xaxis-title',
        },
      }

    },

    legend: {
      show: false
    },

    tooltip: {
      enabled: false
    },
    noData: {
      text: 'Sin datos de nivel de buzón',
      align: 'center',
      verticalAlign: 'middle',
      offsetX: 0,
      offsetY: 0,
      style: {
        color: undefined,
        fontSize: '14px',
        fontFamily: undefined
      }
    },
    plotOptions: {
      area: {
        fillTo: 'end',
      }
    }
  }

  //states
  const [dataGraph, setDataGraph] = useState<ApexAxisChartSeries>(initialOptionsGraph)

  //effects
  useEffect(() => {
    setDataGraph((s: ApexAxisChartSeries) => $u(s, {
      [0]: { data: { $set: coordinates } },
      [1]: { data: { $set: nominal } }
    }))


  }, [nominal, coordinates])


  return isLoading ? (
    <Col>
      <LoadingSpinner />
    </Col>
  ) : (
    <div id="chart" style={{ width: '100%', height: '100%' }}>

      <ReactApexChart
        options={optionGraph}
        series={dataGraph}
        type="area"
        height={'100%'}
      />
    </div>

  )

}

export default LevelMeasurementsChart
import { LocalizedColumnsCallback } from '../../Common/Utils/LocalizedColumnsCallback'
import { Utils } from '../../Common/Utils/Utils'
import { IEquipment } from '../interfaces/Masters/Equipment/Equiment'

export const EquipmentsColumns: LocalizedColumnsCallback<IEquipment> = (
  intl
) => {
  const caps = Utils.capitalize(intl)
  return [
    { selector: 'name', name: caps('labels:name') },
    {
      selector: 'sensor_tag',
      name: caps('labels:sensor_tag'),
    },
    {
      selector: 'client_name',
      name: caps('labels:client'),
    },
  ]
}

import React, { useEffect, useState } from 'react'
import { Col, Form } from 'react-bootstrap'
import { Controller, ErrorMessage, useForm } from 'react-hook-form'
import * as yup from 'yup'

import { useShowAlertsRequest } from '../../../Common/Hooks/useShowAlertsRequest'
import { Buttons } from '../../../Components/Common/Buttons'
import { LoadingSpinner } from '../../../Components/Common/LoadingSpinner'
import { FileInputWithPreviewImage } from '../../../Components/Forms/FileInputWithPreviewImage'
import { Textbox } from '../../../Components/Forms/Textbox'
import { BaseContentView } from '../../../Components/views/BaseContentView'
import { IEmpresa } from '../../../Data/interfaces/Config/Empresa'
import {
  getCompanyProfileService,
  setCompanyProfileService,
} from '../../../Services/Config/company'

import { useFullIntl } from '../../../Common/Hooks/useFullIntl'
import { URL_PATTERN, URL_PHONE_PATTERN } from '../../../Common/Const/Patterns'
import { $u } from '../../../Common/Utils/Reimports'

export const CompanyProfile = () => {
  // states
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [empresa, setEmpresa] = useState<IEmpresa>()
  const [isLoadModule, setLoadModule] = useState<boolean>(true)
  const showToast = useShowAlertsRequest()

  //hooks
  const { capitalize: caps } = useFullIntl()

  // handles
  const getCompanyProfile = async () => {
    setLoadModule(true)
    const responseService = await getCompanyProfileService()
    if (responseService.appearanceMessage == 'success') {
      setEmpresa(responseService.responseMessage as IEmpresa)
    } else {
      showToast({ msgs: responseService })
    }
    setLoadModule(false)
  }

  //form
  //Esquema de validacion
  const CompanyProfileFormSchema = yup.object().shape({
    system_name: yup.string().required(caps('validations:required')),
    rut: yup.string().required(caps('validations:required')),
    name: yup.string().required(caps('validations:required')),
    email: yup
      .string()
      .email(caps('validations:invalid_format_email'))
      .required(caps('validations:required')),
    phone: yup.string().matches(URL_PHONE_PATTERN, {
      message: caps('validations:invalid_format_phone'),
      excludeEmptyString: true,
    }),
    site: yup
      .string()
      .trim()
      .matches(URL_PATTERN, {
        message: caps('validations:invalid_format_web'),
        excludeEmptyString: true,
      }),

    direction: yup.string().required(caps('validations:required')),
    giro: yup.string().optional(),
    bank_account: yup
      .number()
      .transform((currentValue, originalValue) => {
        return originalValue === '' ? undefined : currentValue
      })
      .optional()
      .typeError(caps('validations:invalid_format_number')),
  })

  const { handleSubmit, register, errors, control, setValue } = useForm({
    mode: 'onSubmit',
    submitFocusError: true,
    validationSchema: CompanyProfileFormSchema,
  })

  //effects
  useEffect(() => {
    register('is_chilean')
    getCompanyProfile()
  }, [])

  useEffect(() => {
    empresa !== undefined &&
      isLoadModule === false &&
      setValue([
        { system_name: empresa.system_name },
        { rut: empresa.rut },
        { name: empresa.name },
        { email: empresa.email },
        { phone: empresa.phone },
        { site: empresa.site },
        { giro: empresa.giro },
        { bank_account: empresa.bank_account },
        { direction: empresa.direction },
        { is_chilean: empresa.is_chilean },
      ])
  }, [empresa, isLoadModule])

  async function onSubmit(data: any) {
    setIsLoading(true)
    const formData: FormData = new FormData()
    for (const property in data) {
      let value = data[property]
      if (value !== undefined) {
        if (property == 'background_image' || property == 'logo') {
          value = value[0]
        }
        formData.append(property, value)
      }
    }
    formData.append('_method', 'PATCH')
    const responseService = await setCompanyProfileService(formData)
    showToast({ msgs: responseService })
    setIsLoading(false)
  }

  return (
    <BaseContentView>
      {isLoadModule ? (
        <LoadingSpinner />
      ) : (
        <Col>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Col
              sm={12}
              className={'p-0'}
              style={{
                border: '10px solid #fff',
              }}
            >
              <Controller
                control={control}
                id={'background_image'}
                name={'background_image'}
                as={FileInputWithPreviewImage}
                accept={['jpg', 'jpeg', 'png']}
                height="350px"
                fullImage={true}
                src={empresa && empresa.background_image}
              />

              <ErrorMessage errors={errors} name="logo">
                {({ message }) => (
                  <small className="text-danger">{message}</small>
                )}
              </ErrorMessage>
              <ErrorMessage errors={errors} name="background_image">
                {({ message }) => (
                  <small className="text-danger">{message}</small>
                )}
              </ErrorMessage>
            </Col>
            <Col sm={12} className={'p-0'}>
              <Col sm={6} md={5} lg={4} xl={3} style={{ minWidth: '220px' }}>
                <Controller
                  control={control}
                  id={'logo'}
                  name={'logo'}
                  as={FileInputWithPreviewImage}
                  accept={['jpg', 'jpeg', 'png']}
                  styleContainer={{
                    margin: '-100px 20px 20px 20px',
                    border: '10px solid #fff',
                    background: '#002060',
                  }}
                  fullImage={true}
                  src={empresa && empresa.logo}
                />
              </Col>
              <Col sm={12} lg={8} md={7} xl={9} className={'pl-0 pr-0'}>
                <Col sm={12} className={'pl-0 pr-0'}>
                  <Col sm={4} className={'pt-3'}>
                    <Textbox
                      label={`labels:name_system`}
                      name={'system_name'}
                      id={'system_name'}
                      ref={register}
                      errorForm={errors.system_name}
                      placeholder={'placeholders.required'}
                      isLabelRequired={true}
                    />
                  </Col>
                  <Col sm={4} className={'pt-3'}>
                    <label>
                      <b>
                        RUT <span className="text-danger">(*)</span> :{' '}
                      </b>
                    </label>

                    <div className="input-group mb-3">
                      <div
                        className="input-group-prepend"
                        style={{ width: '30%' }}
                      >
                        <button
                          className="btn btn-outline-secondary dropdown-toggle"
                          type="button"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                          style={{ width: '100%' }}
                        >
                          {empresa?.is_chilean ? 'Chile' : 'Otro'}
                        </button>
                        <div className="dropdown-menu">
                          <a
                            className="dropdown-item"
                            href="#"
                            onClick={() => {
                              setValue('is_chilean', true)
                              setEmpresa((s) =>
                                $u(s, {
                                  $merge: {
                                    is_chilean: true,
                                  },
                                })
                              )
                            }}
                          >
                            Chile
                          </a>
                          <a
                            className="dropdown-item"
                            href="#"
                            onClick={() => {
                              setValue('is_chilean', false)

                              setEmpresa((s) =>
                                $u(s, {
                                  $merge: {
                                    is_chilean: false,
                                  },
                                })
                              )
                            }}
                          >
                            Otro
                          </a>
                        </div>
                      </div>
                      <Textbox
                        name={'rut'}
                        id={'rut'}
                        ref={register}
                        errorForm={errors.rut}
                        placeholder={'placeholders.required'}
                        isLabelRequired={true}
                      />
                    </div>
                  </Col>
                  <Col sm={4} className="pt-3">
                    <Textbox
                      label={`labels:name`}
                      name={'name'}
                      id={'name'}
                      ref={register}
                      errorForm={errors.name}
                      placeholder={'placeholders.required'}
                      isLabelRequired={true}
                    />
                  </Col>
                </Col>
                <Col sm={12} className={'pl-0 pr-0'}>
                  <Col sm={4} className="pt-3">
                    <Textbox
                      label={`labels:email`}
                      name={'email'}
                      id={'email'}
                      ref={register}
                      placeholder={'placeholders.required'}
                      errorForm={errors.email}
                      isLabelRequired={true}
                    />
                  </Col>
                  <Col sm={4} className="pt-3">
                    <Textbox
                      label={`labels:phone`}
                      name={'phone'}
                      id={'phone'}
                      ref={register}
                      placeholder={'placeholders.optionals'}
                      errorForm={errors.phone}
                    />
                  </Col>
                  <Col sm={4} className="pt-3">
                    <Textbox
                      label={`labels:corporate_web`}
                      name={'site'}
                      id={'site'}
                      ref={register}
                      placeholder={'placeholders.optionals'}
                      errorForm={errors.site}
                    />
                  </Col>
                </Col>
              </Col>
            </Col>
            <Col sm={12} className={'pl-0 pr-0'}>
              <Col sm={3} className="pt-3">
                <Textbox
                  label={`labels:giro`}
                  name={'giro'}
                  id={'giro'}
                  ref={register}
                  placeholder={'placeholders.optionals'}
                  errorForm={errors.giro}
                />
              </Col>
              <Col sm={3} className="pt-3">
                <Textbox
                  label={`labels:current_account`}
                  name={'bank_account'}
                  id={'bank_account'}
                  ref={register}
                  placeholder={'placeholders.optionals'}
                  errorForm={errors.bank_account}
                />
              </Col>
              <Col sm={6} className="pt-3">
                <Textbox
                  label={`labels:common.address`}
                  name={'direction'}
                  id={'direction'}
                  ref={register}
                  placeholder={'placeholders.required'}
                  errorForm={errors.direction}
                  isLabelRequired={true}
                />
              </Col>
            </Col>
            <Buttons.Form isLoading={isLoading} />
          </Form>
        </Col>
      )}
    </BaseContentView>
  )
}

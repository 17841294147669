import React, { useEffect, useState } from 'react'
import { Col, Form } from 'react-bootstrap'
import { Controller, ErrorMessage, useForm } from 'react-hook-form'
import { useFullIntl } from '../../../../Common/Hooks/useFullIntl'
import { ApiSelect } from '../../../../Components/Api/ApiSelect'
import { Buttons } from '../../../../Components/Common/Buttons'
import { Textbox } from '../../../../Components/Forms/Textbox'
import { IAlarmsVars, IEquipmentAlarms } from '../../../../Data/interfaces/Masters/Equipment/Equiment'
import * as yup from 'yup'
import { $u } from '../../../../Common/Utils/Reimports'
import DualListBox, { Option } from 'react-dual-listbox'
import DualListLang from '../../../../Data/interfaces/Common/DualListLang'
import { useShowAlertsRequest } from '../../../../Common/Hooks/useShowAlertsRequest'
import { getNotyfiableUsersService, postEquipmetAlarmsService, setEquipmetAlarmsService } from '../../../../Services/Masters/Equipments/EquimentsServices'
import { useCommonRoutes } from '../../../../Common/Hooks/useCommonRoutes'

interface IFormAlarmEquipment {
  dataInitial?: IEquipmentAlarms
  isUpdate?: boolean
}

function FormAlarmEquipment({ dataInitial, isUpdate }: IFormAlarmEquipment) {

  //hooks
  const { capitalize: caps } = useFullIntl()
  const showToast = useShowAlertsRequest()
  const { goBack } = useCommonRoutes()

  //states
  const [isSaving, setIsSaving] = useState<boolean>(false)
  const [notyfiableUsers, setNotyfiableUsers] = useState<Option<unknown>[]>([])
  const [notyfiableUsersSelected, setNotyfiableUsersSelected] = useState<number[] | undefined>([])
  const [activeAlarms, setActiveAlarms] = useState<IAlarmsVars>({
    occupation: { active: false, value: null },
    batery_percent: { active: false, value: null }
  })
  const [isReadyUsersNotifiable, setIsReadyUsersNotifiable] = useState<boolean>(false)


  //schema
  const FormAlarmEquipmentSchema = yup.object().shape({
    id_equipment: yup.number().required(caps('validations:required')),
    alarm_min_occupation: yup.number().nullable(true)
      .transform((value, originalValue) => {
        return originalValue === "" ? null : value;
      })
      .when('is_alarm_min_occupation', {
        is: true,
        then: yup.number()
          .typeError(caps('validations:invalid_format_number'))
          .required(caps('validations:required'))
      }),
    alarm_min_batery: yup.number().nullable(true)
      .transform((value, originalValue) => {
        return originalValue === "" ? null : value;
      })
      .when('is_alarm_min_batery', {
        is: true,
        then: yup.number()
          .typeError(caps('validations:invalid_format_number'))
          .required(caps('validations:required'))
      }),
    notify_users: yup.array().required('Seleccione almenos un (1) usuario a notificar')
  })

  //form
  const { handleSubmit, register, errors, control, setValue } = useForm<IEquipmentAlarms>({
    mode: 'onSubmit',
    submitFocusError: true,
    validationSchema: FormAlarmEquipmentSchema
  })

  //handles
  const onSubmit = async (data: IEquipmentAlarms) => {
    setIsSaving(true)
    const responseService = isUpdate
      ? await setEquipmetAlarmsService(data)
      : await postEquipmetAlarmsService(data)

    responseService.appearanceMessage == 'success' && goBack()
    showToast({ msgs: responseService })
    setIsSaving(false)
  }

  const fetchNotyfiableUsers = async (id_equipment: number) => {
    setIsReadyUsersNotifiable(false)
    const responseUsers = await getNotyfiableUsersService({ id_equipment })
    if (responseUsers.appearanceMessage === 'success') {
      const notyfiableUsers: Option<unknown>[] = responseUsers.responseMessage as Option<unknown>[]
      setNotyfiableUsers(notyfiableUsers)
    } else {
      showToast({ msgs: responseUsers })
    }
    setIsReadyUsersNotifiable(true)
  }

  //effects
  useEffect(() => {
    if (dataInitial !== undefined) {
      setValue([
        { id_equipment: dataInitial.id_equipment },
        { is_alarm_min_occupation: dataInitial.is_alarm_min_occupation },
        { alarm_min_occupation: dataInitial.alarm_min_occupation },
        { is_alarm_min_batery: dataInitial.is_alarm_min_batery },
        { alarm_min_batery: dataInitial.alarm_min_batery }
      ])
      setActiveAlarms((state) => $u(state, {
        occupation: {
          active: { $set: dataInitial.is_alarm_min_occupation },
          value: { $set: dataInitial.alarm_min_occupation }
        },
        batery_percent: {
          active: { $set: dataInitial.is_alarm_min_batery },
          value: { $set: dataInitial.alarm_min_batery }
        }
      }))
      setNotyfiableUsersSelected(dataInitial.notify_users)
      setValue([{ notify_users: dataInitial.notify_users }])
    } else {
      setNotyfiableUsersSelected([])
      setValue([{ notify_users: [] }])
    }
  }, [dataInitial, notyfiableUsers])


  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Col sm={12} className={'pl-0 pr-0'}>
        <Col sm={3} className="pt-3">
          <Controller
            as={ApiSelect}
            control={control}
            name="id_equipment"
            placeholder="Seleccione ..."
            label="labels:equipment"
            isLabelRequired={true}
            isSelectFirtsOption={false}
            source={'equipment'}
            queryParams={{ withoutPagination: 1, filterWithoutAlarms: dataInitial === undefined }}
            selector={(option: any) => {
              return { label: option.name, value: option.id }
            }}
            errorForm={errors.id_equipment}

            onChange={(id_equipment) => {
              fetchNotyfiableUsers(id_equipment[0])
              return id_equipment[0]
            }}
            isDisabled={isUpdate}
          />
        </Col>
        <Col xs={6} sm={2} className="pt-3">
          <input
            type="checkbox"
            name={'is_alarm_min_occupation'}
            id={'is_alarm_min_occupation'}
            ref={register}
            className="CheckWithInput"
            checked={activeAlarms.occupation.active}
            onChange={() => {
              const isActiveAlarm = !activeAlarms.occupation.active
              setActiveAlarms((state) => $u(state, { occupation: { active: { $set: isActiveAlarm } } }))
            }}
            disabled={!isReadyUsersNotifiable}
          />

          <Textbox
            label={`labels:alarm_min_occupation`}
            name={'alarm_min_occupation'}
            id={'alarm_min_occupation'}
            ref={register}
            className="InputWithCheck"
            onlyNumber={true}
            errorForm={errors.alarm_min_occupation}
            placeholder={'valor para alarma'}
            isLabelRequired={activeAlarms.occupation.active}
            disabled={!activeAlarms.occupation.active || !isReadyUsersNotifiable}
          />

        </Col>
        <Col xs={6} sm={2} className="pt-3">
          <input
            type="checkbox"
            name={'is_alarm_min_batery'}
            id={'is_alarm_min_batery'}
            ref={register}
            className="CheckWithInput"
            checked={activeAlarms.batery_percent.active}
            onChange={() => {
              const isActiveAlarm = !activeAlarms.batery_percent.active
              setActiveAlarms((state) => $u(state, { batery_percent: { active: { $set: isActiveAlarm } } }))
            }}
            disabled={!isReadyUsersNotifiable}
          />

          <Textbox
            label={`labels:alarm_min_batery`}
            name={'alarm_min_batery'}
            id={'alarm_min_batery'}
            ref={register}
            className="InputWithCheck"
            onlyNumber={true}
            errorForm={errors.alarm_min_batery}
            placeholder={'valor para alarma'}
            isLabelRequired={activeAlarms.batery_percent.active}
            disabled={!activeAlarms.batery_percent.active || !isReadyUsersNotifiable}
          />

        </Col>

      </Col>
      <Col sm={12} className="pt-4">
        <label>
          <b>
            Usuarios a notificar
            <span className="text-danger"> (*)</span>:
          </b>
        </label>
        <Controller
          control={control}
          as={DualListBox}
          id={'notify_users'}
          name={'notify_users'}
          options={notyfiableUsers}
          selected={notyfiableUsersSelected}
          canFilter
          filterPlaceholder={'Buscar usuario...'}
          showHeaderLabels={true}
          lang={DualListLang}
          onChange={(data) => {
            setNotyfiableUsersSelected(data[0])
            return data[0]
          }}
          disabled={!isReadyUsersNotifiable}
        />
        <ErrorMessage errors={errors} name="notify_users">
          {({ message }) => <small className="text-danger">{message}</small>}
        </ErrorMessage>
      </Col>
      <Buttons.Form isLoading={isSaving} />
    </Form>
  )
}

export default FormAlarmEquipment
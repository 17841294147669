import { AxiosError, AxiosResponse } from 'axios'
import { ax } from '../../../Common/Utils/AxiosCustom'
import { IUsageReport } from '../../../Pages/Reportes/UsageReport/interfaces'
import { HttpUtils } from '../../../Common/Utils/HttpUtils'
import { IServicesResponse } from '../../../Data/interfaces/Common/ServicesResponse'
import { $j } from '../../../Common/Utils/Reimports'

export interface getDataUsageReportParams {
  client?: string
  equipment_id: string | undefined
  date?: string
  coordinate: number
  nominal: number
}

export const getDataUsageReportService = async (
  params: getDataUsageReportParams
) => {
  const serviceResponse: IServicesResponse<IUsageReport> = {
    appearanceMessage: 'error',
  }

  await ax
    .get<IUsageReport>('equipment/chartFromRechart', { params })
    .then((res: AxiosResponse) => {
      serviceResponse.appearanceMessage = 'success'
      serviceResponse.responseMessage = res.data
    })
    .catch((error: AxiosError) => {
      serviceResponse.responseMessage = HttpUtils.localizedErrorMsg(error)
    })

  return serviceResponse
}

export const getDatesInReportService = async ({
  idEquipment,
  month,
}: {
  idEquipment: string
  month: string
}) => {
  const serviceResponse: IServicesResponse<IUsageReport> = {
    appearanceMessage: 'error',
  }

  await ax
    .get<IUsageReport>($j('equipment/get-info-by-date', idEquipment), {
      params: { month },
    })
    .then((res: AxiosResponse) => {
      serviceResponse.appearanceMessage = 'success'
      serviceResponse.responseMessage = res.data
    })
    .catch((error: AxiosError) => {
      serviceResponse.responseMessage = HttpUtils.localizedErrorMsg(error)
    })

  return serviceResponse
}

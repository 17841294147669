import React, { useState, useEffect, useRef, useMemo } from 'react'
import { Button, Col, Modal } from 'react-bootstrap'
import { IDataTableColumn } from 'react-data-table-component'
import { BounceLoader } from 'react-spinners'
import { useToasts } from 'react-toast-notifications'
import { useFullIntl } from '../../../Common/Hooks/useFullIntl'
import { useLocalization } from '../../../Common/Hooks/useLocalization'
import { useShortModal } from '../../../Common/Hooks/useModal'
import { useReload } from '../../../Common/Hooks/useReload'
import { useSearch } from '../../../Common/Hooks/useSearch'
import { useShowAlertsRequest } from '../../../Common/Hooks/useShowAlertsRequest'
import { $u, $v } from '../../../Common/Utils/Reimports'
import { ApiTable } from '../../../Components/Api/ApiTable'
import { SearchBar } from '../../../Components/Forms/SearchBar'
import { BaseContentView } from '../../../Components/views/BaseContentView'
import { IServicesResponse } from '../../../Data/interfaces/Common/ServicesResponse'
import {
  IPermisoModule,
  IRolesAdminitracion,
  IRolesAndPermisions,
  IRoleSimple,
  roleChange,
} from '../../../Data/interfaces/Config/Rol'
import { getAllRolesService } from '../../../Services/Config/roles/RolesServices'
import AgregarRolModal from './components/AgregarRolModal'
import CambiarPermisos from './components/CambiarPermisos'
import UpdateRolModal from './components/UpdateRolModal'

const initial: IRolesAdminitracion = {
  data: {
    permisos: [],
  },

  dataOfChangeOfPermission: {
    roleName: undefined,
    roleId: undefined,
    entityName: undefined,
    permission: undefined,
    entityId: undefined,
  },
}

export const AdministrarRoles = () => {
  // HOOKS
  const { capitalize: caps } = useFullIntl()
  const [search, doSearch] = useSearch()
  const [reloadRoles, doReloadRoles] = useReload()
  const showToast = useShowAlertsRequest()

  // States
  const [data, setData] = useState(initial.data)
  const [rolChange, setRolChange] = useState<IRoleSimple>()
  const [reload, setReload] = useState(true)
  const [dataRoles, setDataRoles] = useState<IPermisoModule[]>([])
  const [loadingg, setLoadingg] = useState(false)
  const [dataOfChangeOfPermission, setDataOfChangeOfPermission] =
    useState<roleChange>(initial.dataOfChangeOfPermission)

  //Modals
  const modalAgregar = useShortModal()
  const modalChangePermisson = useShortModal()
  const modalChangeRol = useShortModal()

  //effects
  useEffect(() => {
    const getRolesAll = async () => {
      const onSuccess = (data: IRolesAndPermisions) => {
        setDataRoles(data.permissionsByModule)
        setData((s) => $u(s, { roles: { $set: data.roles } }))
      }

      const onError = (msg: IServicesResponse<undefined>) => {
        showToast({
          msgs: msg,
        })
      }

      const onFinish = () => {
        setLoadingg(() => false)
      }

      await getAllRolesService(onSuccess, onError, onFinish)

      setReload(() => false)
    }
    if (!reload) {
      return
    }

    setLoadingg(true)
    getRolesAll()
  }, [reload])

  //handles
  const handleClickModulePermissions = (roleChange: roleChange) => {
    modalChangePermisson.show()
    setDataOfChangeOfPermission(roleChange)
  }

  function handleClickAgregarRol() {
    modalAgregar.show()
  }

  function handleClickEditarRol(data: any) {
    setRolChange(data)
    modalChangeRol.show()
  }

  function tipoPermissions(permiso: number) {
    switch (permiso) {
      case 0:
        return 'fas fa-eye-slash'
      case 1:
        return 'fas fa-eye'
      case 2:
        return 'fas fa-plus-circle'
      case 3:
        return 'fas fa-edit'
      case 4:
        return 'fas fa-trash-alt'
      default:
        return 'fas fa-exclamation-triangle'
    }
  }
  //columns
  const RolesAdministrarColumns =
    useMemo((): IDataTableColumn<IPermisoModule>[] => {
      const baseColumns: IDataTableColumn<IPermisoModule>[] = [
        {
          name: 'MÓDULO',
          selector: (data: IPermisoModule) => caps(`menus:${data.module}`),
          width: '150px',
        },
      ]

      if (data.roles != undefined) {
        data.roles!.map((value) => {
          baseColumns.push({
            name: (
              <b onClick={() => handleClickEditarRol(value)}>
                {$v.upperCase(value.nombre)}
              </b>
            ),
            selector: value.nombre,
            center: true,
            cell: (moduleData: IPermisoModule) => {
              return (
                <>
                  <Col sm={6} className="text-center">
                    {
                      <i
                        className={tipoPermissions(
                          moduleData.permissions[value.nombre] as number
                        ).toString()}
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          handleClickModulePermissions({
                            roleName: value.nombre,
                            roleId: value.codigo,
                            entityName: moduleData.module,
                            permission: moduleData.permissions[
                              value.nombre
                            ] as number,
                            entityId: moduleData.id,
                          })
                        }}
                      />
                    }
                  </Col>
                </>
              )
            },
          })
        })
      }
      doReloadRoles()

      return baseColumns
    }, [data.roles])

  return (
    <BaseContentView title="titles:roles_management">
      {/* BOTONERA */}
      <div className="col-12 mb-2">
        <Button
          className="btn-primary mr-3"
          id="btnNewQuote"
          onClick={handleClickAgregarRol}
        >
          <i className="fas fa-plus mr-3" />
          {caps('labels:links.add')}
        </Button>
      </div>

      {/* INPUT DE BUSQUEDA */}
      <div className="col-12 text-right pr-0 pl-0">
        <div
          className="col-lg-3 col-md-5 col-sm-6"
          style={{ verticalAlign: 'bottom' }}
        >
          <SearchBar onChange={doSearch} />
        </div>
      </div>
      <Col sm={12}>
        {loadingg ? (
          <BounceLoader
            css={{ margin: '2.25rem auto' } as any}
            color="var(--primary)"
          />
        ) : (
          <ApiTable<IPermisoModule>
            columns={RolesAdministrarColumns}
            source={dataRoles ?? []}
            search={search}
            reload={reloadRoles}
          />
        )}
      </Col>
      <div>
        <CambiarPermisos
          modal={modalChangePermisson}
          setReload={setReload}
          dataOfChangeOfPermission={dataOfChangeOfPermission}
          setDataOfChangeOfPermission={setDataOfChangeOfPermission}
        />
      </div>

      <div>
        <UpdateRolModal
          modal={modalChangeRol}
          rol={rolChange}
          setReload={setReload}
        />
      </div>

      <div>
        <AgregarRolModal modalAgregar={modalAgregar} setReload={setReload} />
      </div>
    </BaseContentView>
  )
}

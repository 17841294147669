import React from 'react'
import ReactApexChart from 'react-apexcharts'
import { Col } from 'react-bootstrap'
import { ColorsAlarms } from '../../../../Common/Const/Enums/ColorsAlarms'

function BateryGauge({ value }: { value: number }) {
  return (
    <Col sm={12} className="px-0">
      <Col sm={12} className="d-flex justify-content-between px-0" style={{ color: '#002060', fontSize: '16px' }}>
        <b> Batería </b>
        <b style={{ fontSize: '16px' }}>{`${value}%`}</b>
      </Col>
      <Col className="d-flex align-items-center px-0">
        <Col xs={1} className="px-0 d-flex justify-content-center">
          <i className="fas fa-car-battery fa-2x" style={{ color: '#002060' }} />
        </Col>
        <Col xs={11} className="px-0">
          <div id="chart-battery" style={{ height: '100%' }}>
            <ReactApexChart
              options={{
                chart: {
                  type: "bar",
                  stacked: true,
                  toolbar: {
                    show: false
                  },
                  sparkline: {
                    enabled: true
                  },
                },
                plotOptions: {
                  bar: {
                    horizontal: true,
                    colors: {
                      ranges: [
                        {
                          from: 0,
                          to: 32,
                          color: ColorsAlarms.Red
                        },
                        {
                          from: 33,
                          to: 65,
                          color: ColorsAlarms.Yellow
                        },
                        {
                          from: 66,
                          to: 100,
                          color: ColorsAlarms.Green
                        }
                      ],
                      backgroundBarColors: ["#ccc"]
                    }
                  }
                },
                stroke: {
                  width: 0
                },
                tooltip: {
                  enabled: false
                },
                yaxis: {
                  max: 100
                },
                fill: {
                  opacity: 1
                },
                states: {
                  hover: {
                    filter: {
                      type: 'none',
                    }
                  },
                  active: {
                    allowMultipleDataPointsSelection: false,
                    filter: {
                      type: 'none',
                    }
                  },
                }
              }}
              series={[{ data: [value] }]}
              type='bar'
              height={'30px'}
            />
          </div>
        </Col>
      </Col>
    </Col>
  )
}

export default BateryGauge
import React, { Fragment } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { varsWithAlarms } from '../../Common/Const';
import { LocalizedColumnsCallback } from '../../Common/Utils/LocalizedColumnsCallback'
import { Utils } from '../../Common/Utils/Utils'
import { IEquipment } from '../interfaces/Masters/Equipment/Equiment'

export const AlarmsEquipmentColumns: LocalizedColumnsCallback<IEquipment> = (
  intl
) => {
  const caps = Utils.capitalize(intl)
  return [
    { selector: 'name', name: caps('labels:name') },
    { selector: 'client_name', name: caps('labels:client') },
    {
      name: caps('alarmas'),
      center: true,
      cell: (equipo) => (<>
        <span className="pr-2">{equipo.alarms_active?.length}</span>
        <OverlayTrigger
          placement='top'
          overlay={
            <Tooltip id='info-proveedores'>
              {
                equipo.alarms_active?.map(alarms =>
                  <Fragment key={`alarms-eq-${equipo.id}`}>
                    <span> {caps(varsWithAlarms[alarms.variable])}</span><br />
                  </Fragment>)
              }
            </Tooltip>}>
          <i className='fas fa-eye' color='red' />
        </OverlayTrigger>
      </>

      )
    }
  ]
}
import { ApexOptions } from 'apexcharts';
import React, { useEffect, useState } from 'react'
import ReactApexChart from 'react-apexcharts'
import { Col } from 'react-bootstrap';
import { $m } from '../../../../Common/Utils/Reimports';
import { LoadingSpinner } from '../../../../Components/Common/LoadingSpinner';

// const initialOptionsGraph = {
//   series: [{
//     data: [{
//       x: 'category A',
//       y: 0
//     }, {
//       x: 'category B',
//       y: 80
//     }, {
//       x: 'category C',
//       y: 60
//     },
//     {
//       x: 'category d',
//       y: 60
//     },
//     {
//       x: 'category e',
//       y: 70
//     },
//     {
//       x: 'category e',
//       y: 90
//     },
//     {
//       x: 'category e',
//       y: 70
//     }, {
//       x: 'category e',
//       y: 70
//     }, {
//       x: 'category e',
//       y: 80
//     }, {
//       x: 'category e',
//       y: 60
//     }, {
//       x: 'category e',
//       y: 60
//     },
//     {
//       x: 'category e',
//       y: 85
//     }]
//   }, {
//     data: [{
//       x: 'category A',
//       y: 90
//     }, {
//       x: 'category B',
//       y: 10
//     }, {
//       x: 'category C',
//       y: 20
//     },
//     {
//       x: 'category d',
//       y: 10
//     },
//     {
//       x: 'category e',
//       y: 30
//     }, {
//       x: 'category e',
//       y: 10
//     }, {
//       x: 'category e',
//       y: 20
//     }, {
//       x: 'category e',
//       y: 10
//     }, {
//       x: 'category e',
//       y: 10
//     }, {
//       x: 'category e',
//       y: 30
//     }, {
//       x: 'category e',
//       y: 30
//     },
//     {
//       x: 'category e',
//       y: 5
//     }]
//   }
//   ],
// };
interface IPropsHistoricalMeditationChart {
  data: ApexAxisChartSeries,
  isLoading?: boolean
}
function HistoricalMeditationChart({ data, isLoading }: IPropsHistoricalMeditationChart) {
  const [dataGraph, setDataGraph] = useState<ApexOptions>({
    chart: {
      type: 'bar',
      zoom: {
        enabled: false
      },
      stacked: true,

    },
    fill: {
      type: "solid",
      colors: [({ dataPointIndex }: any) => {
        return dataPointIndex === 0 ? '#a5a5a7' : 'transparent'
      },
      ({ dataPointIndex, w }: any) => {
        if (dataPointIndex === 0) {
          return '#a5a5a7'
        } else {
          const dataOcupation = w.config.series[2].data;
          const currentValue = dataOcupation[dataPointIndex]["y"]
          const lastValue = dataOcupation[dataPointIndex - 1]["y"]

          return isIncrease({ currentValue, lastValue }) ? '#002060' : '#FF5400'
        }
      },
        'transparent']
    },
    dataLabels: {
      enabledOnSeries: [1],
      style: {
        fontSize: '10px',
        fontFamily: 'Helvetica, Arial, sans-serif',
        colors: [({ dataPointIndex, w }: any) => {
          if (dataPointIndex === 0) {
            return '#a5a5a7'
          } else {
            const dataOcupation = w.config.series[2].data;
            const currentValue = dataOcupation[dataPointIndex]["y"]
            const lastValue = dataOcupation[dataPointIndex - 1]["y"]

            return isIncrease({ currentValue, lastValue }) ? '#002060' : '#FF5400'
          }
        }]
      },
      offsetY: -15,
      formatter: (val, opts: { val: number, opts?: any }) => {
        const { dataPointIndex, w } = opts as any
        const value: number = val as number

        if (dataPointIndex === 0) {
          return value
        } else {

          const dataOcupation = w.config.series[2].data;
          const currentValue = dataOcupation[dataPointIndex]["y"]
          const lastValue = dataOcupation[dataPointIndex - 1]["y"]

          return isIncrease({ currentValue, lastValue }) ? value : `-${value}`
        }
      }

    },
    grid: {
      show: true,
      borderColor: '#ccc',
      strokeDashArray: 2,
      position: "back",
      xaxis: {
        lines: {
          show: true
        }
      },
      yaxis: {
        lines: {
          show: true

        }
      },

    },
    yaxis: {
      labels: {
        show: true,
        style: {
          fontSize: '11px',
          fontFamily: 'Helvetica, Arial, sans-serif',
        },
      },
      min: 0,
      max: 100,
      decimalsInFloat: 0,
      tickAmount: 5,
      title: {
        text: "% Nivel",
        offsetX: 0,
        offsetY: 0,
        style: {
          color: "#000",
          fontSize: '12px',
          fontFamily: 'Helvetica, Arial, sans-serif',
          fontWeight: 600,
          cssClass: 'apexcharts-xaxis-title',
        },
      },
    },
    noData: {
      text: 'Sin datos de historial de medición',
      align: 'center',
      verticalAlign: 'middle',
      offsetX: 0,
      offsetY: 0,
      style: {
        color: undefined,
        fontSize: '14px',
        fontFamily: undefined
      }
    },
    plotOptions: {
      bar: {
        columnWidth: '95%',
        dataLabels: {
          // orientation: 'vertical',
          position: 'top',
          hideOverflowingLabels: false

        }
      }
    },
    states: {
      hover: {
        filter: {
          type: 'none',
        }
      }
    },
    tooltip: {
      enabled: true,
      intersect: false,
      followCursor: true,
      y: {
        title: {
          formatter: () => 'Ocupación',
        },
        formatter: function (value, { series, dataPointIndex }) {
          return `${series[2][dataPointIndex]}%`
        }
      },
      x: {
        show: false
      }

    },
    colors: ['#002060'],
    legend: {
      show: true,
      showForSingleSeries: true,
      customLegendItems: ['Aumento', 'Disminución', 'Total'],
      position: 'top',
      markers: {
        fillColors: ['#002060', '#FF5400', '#a5a5a5']
      }
    },
    xaxis: {
      type: 'category',
      labels: {
        formatter: function (val) {
          $m.locale('es')
          const date = $m(val, 'YYYY-MM-DD HH:mm:ss', true)
          return date.isValid() ? date.format("MMM DD / HH:mm") : '';
        },
        style: {
          fontSize: '11px',
          fontFamily: 'Helvetica, Arial, sans-serif',
        },
      },

    },
    series: [],
  })


  //callbacks
  const isIncrease = ({ currentValue, lastValue }: {
    currentValue: number
    lastValue: number
  }) => {
    return currentValue >= lastValue
  }

  useEffect(() => {
    setDataGraph({ ...dataGraph, series: data })

  }, [data])

  return isLoading ? (
    <Col>
      <LoadingSpinner />
    </Col>
  ) : (
    <div id="chart" style={{ width: '100%' }}>
      <ReactApexChart
        options={dataGraph}
        series={dataGraph.series}
        height={'100%'}
        type="bar"
      />
    </div>
  )
}

export default HistoricalMeditationChart
// Localization actions
export const CHANGE_LOCALE = 'CHANGE_LOCALE'

// Auth actions
export const LOGIN = 'LOGIN'
export const LOGOUT = 'LOGOUT'

// Extra dashboard actions
export const RESET_DASHBOARD = 'RESET_DASHBOARD'
export const LOAD_USER_MENU = 'LOAD_USER_MENU'

// Utils
export const SET_LOADING = 'SET_LOADING'
export const PUSH_ERRORS = 'PUSH_ERRORS'
export const DISMISS_ALL = 'DISMISS_ALL'
export const DISMISS_ERROR = 'DISMISS_ERROR'
export const SET_PERMISSIONS = 'SET_PERMISSIONS'

export const SET_NOTIFICATION_MODULE = 'SET_NOTIFICATION_MODULE'
export const SET_BADGES = 'SET_BADGES'

import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Col, Form, Row } from 'react-bootstrap'
import * as yup from 'yup'

//internals hooks
import { useFullIntl } from '../../Common/Hooks/useFullIntl'

//internals components
import { BaseContentView } from '../../Components/views/BaseContentView'
import { Textbox } from '../../Components/Forms/Textbox'
import { Buttons } from '../../Components/Common/Buttons'
import { ServiceChangePassword } from '../../Services/AuthService'
import { IChangePassword } from '../../Data/interfaces/Auth/IAuth'
import { useShowAlertsRequest } from '../../Common/Hooks/useShowAlertsRequest'

export const CambiarContrasena = () => {
  //state
  const [isLoading, setIsLoading] = useState<boolean>()

  //HOOKS
  const { capitalize: caps } = useFullIntl()
  const showToast = useShowAlertsRequest()

  //Esquema de validacion
  const changePasswordSchema = yup.object().shape({
    old_password: yup.string().required(caps('validations:required')),
    password: yup
      .string()
      .required(caps('validations:required'))
      .min(8, caps('validations:min_length', { count: 8 })),
    confirm_password: yup
      .string()
      .required(caps('validations:required'))
      .oneOf(
        [yup.ref('password')],
        caps('validations:password_not_confirmated')
      ),
  })

  const { handleSubmit, register, errors, reset } = useForm<IChangePassword>({
    mode: 'onSubmit',
    validationSchema: changePasswordSchema,
    submitFocusError: true,
  })

  const changePassword = async (data: IChangePassword) => {
    setIsLoading(true)
    const response = await ServiceChangePassword(data)
    showToast({ msgs: response })
    setIsLoading(false)
    response.appearanceMessage == 'success' && reset()
  }

  return (
    <BaseContentView title="titles:change_password">
      <Row>
        <Col sm={4} style={{ margin: 'auto' }}>
          <Form onSubmit={handleSubmit(changePassword)}>
            <Form.Group>
              <Textbox
                type="password"
                label={`labels:password_current`}
                name={'old_password'}
                id={'old_password'}
                ref={register}
                errorForm={errors.old_password}
              />
            </Form.Group>
            <Form.Group>
              <Textbox
                type="password"
                label={`labels:password_new`}
                name={'password'}
                id={'password'}
                ref={register}
                errorForm={errors.password}
              />
            </Form.Group>
            <Form.Group>
              <Textbox
                type="password"
                label={`labels:password_new_confirmation`}
                name={'confirm_password'}
                id={'confirm_password'}
                ref={register}
                errorForm={errors.confirm_password}
              />
            </Form.Group>
            <Buttons.Form isLoading={isLoading} />
          </Form>
        </Col>
      </Row>
    </BaseContentView>
  )
}

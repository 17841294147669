import { acceptedFormat } from '../../Components/Forms/FileInputWithPreviewImage'

export class ImageUtils {
  static validateImageFormat(
    file: File,
    acceptedImageFormat: acceptedFormat[]
  ) {
    const mappedFormat = acceptedImageFormat.join('|')
    const regExp = new RegExp('(' + mappedFormat + ')$', 'i')
    return regExp.test(file.name)
  }
}

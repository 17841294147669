import React, { useEffect, useState } from 'react'
import { Col, Form } from 'react-bootstrap'
import { Controller, ErrorMessage, useForm } from 'react-hook-form'
import DualListBox, { Option } from 'react-dual-listbox'
import * as yup from 'yup'

import { ApiSelect } from '../../../../Components/Api/ApiSelect'
import { Buttons } from '../../../../Components/Common/Buttons'
import { Textbox } from '../../../../Components/Forms/Textbox'
import DualListLang from '../../../../Data/interfaces/Common/DualListLang'
import { useFullIntl } from '../../../../Common/Hooks/useFullIntl'
import { getEquipmentSimpleService } from '../../../../Services/Masters/Equipments/EquimentsServices'
import { useShowAlertsRequest } from '../../../../Common/Hooks/useShowAlertsRequest'
import { IEquimentDataShort } from '../../../../Data/interfaces/Reports/UsageReports'
import {
  postUserService,
  setUserService,
} from '../../../../Services/Config/users/UserServices'
import { useCommonRoutes } from '../../../../Common/Hooks/useCommonRoutes'
import { IUsuarioForm } from '../../../../Data/interfaces/Config/Usuario'

const FormUser = ({
  dataInitial,
  isUpdate,
}: {
  dataInitial?: IUsuarioForm
  isUpdate?: boolean
}) => {
  //hooks
  const showToast = useShowAlertsRequest()
  const { capitalize: caps } = useFullIntl()
  const { goBack } = useCommonRoutes()

  //states
  const [isSaving, setIsSaving] = useState<boolean>(false)
  const [usernameEdit, setUsernameEdit] = useState<string | null>(null)

  //form
  const UsersFormSchema = yup.object().shape({
    name: yup.string().required(caps('validations:required')),
    surname: yup.string().required(caps('validations:required')),
    username: yup.string().required(caps('validations:required')),
    email: yup
      .string()
      .email(caps('validations:invalid_format_email'))
      .required(caps('validations:required')),
    password: isUpdate
      ? yup.string().transform((currentValue, originalValue) => {
        return originalValue === '' ? undefined : currentValue
      })
      : yup
        .string()
        .required(caps('validations:required'))
        .min(8, caps('validations:min_length', { count: 8 })),

    role: yup
      .string()
      .required(caps('validations:required'))
      .typeError(caps('validations:required')),
    equipments: yup.array().required('Seleccione almenos un (1) equipo'),
  })

  const { handleSubmit, register, errors, control, setValue } =
    useForm<IUsuarioForm>({
      mode: 'onSubmit',
      submitFocusError: true,
      validationSchema: UsersFormSchema,
    })

  const onSubmit = async (data: IUsuarioForm) => {
    setIsSaving(true)
    // const responseService = await postUserService(data)
    const responseService =
      usernameEdit !== null
        ? await setUserService(usernameEdit, data)
        : await postUserService(data)

    responseService.appearanceMessage == 'success' && goBack()

    showToast({ msgs: responseService })
    setIsSaving(false)
  }

  //equipos
  const [equipments, setEquipments] = useState<Option<unknown>[]>([])
  const [equipmentSelected, setEquipmentSelected] = useState<
    number[] | undefined
  >([])
  useEffect(() => {
    const fetchData = async () => {
      const responseService = await getEquipmentSimpleService()
      if (responseService.appearanceMessage === 'success') {
        const services: Option<unknown>[] = (
          responseService.responseMessage as unknown as IEquimentDataShort[]
        ).map((value) => {
          return {
            value: value.id,
            label: `${value.name} ( ${value.sensor_tag} )`,
          }
        })
        setEquipments(services)
      } else {
        showToast({ msgs: responseService })
      }
    }
    fetchData()
  }, [])

  useEffect(() => {
    if (dataInitial !== undefined) {
      setUsernameEdit(dataInitial.username)
      setValue([
        { name: dataInitial.name },
        { surname: dataInitial.surname },
        { username: dataInitial.username },
        { email: dataInitial.email },
        { role: dataInitial.role },
        { password: null },
        { status: dataInitial.status },
      ])
      if (dataInitial.user_equipments !== undefined) {
        setEquipmentSelected(dataInitial.user_equipments)
        setValue([{ equipments: dataInitial.user_equipments }])
      }
    }
  }, [dataInitial, equipments])
  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Col sm={12} className={'pl-0 pr-0'}>
        <Col sm={3} className="pt-3">
          <Textbox
            label={`labels:name`}
            name={'name'}
            id={'name'}
            ref={register}
            errorForm={errors.name}
            placeholder={'placeholders.required'}
            isLabelRequired={true}
          />
        </Col>
        <Col sm={3} className="pt-3">
          <Textbox
            label={`labels:surnames`}
            name={'surname'}
            id={'surname'}
            ref={register}
            errorForm={errors.surname}
            placeholder={'placeholders.required'}
            isLabelRequired={true}
          />
        </Col>
      </Col>
      <Col sm={12} className={'pl-0 pr-0'}>
        <Col sm={3} className="pt-3">
          <Textbox
            label={`labels:username`}
            name={'username'}
            id={'username'}
            ref={register}
            errorForm={errors.username}
            placeholder={'placeholders.required'}
            isLabelRequired={true}
            disabled={dataInitial !== undefined}
          />
        </Col>
        <Col sm={3} className="pt-3">
          <Textbox
            label={`labels:email`}
            name={'email'}
            id={'email'}
            ref={register}
            errorForm={errors.email}
            placeholder={'placeholders.required'}
            isLabelRequired={true}
          />
        </Col>

        <Col sm={3} className="pt-3">
          <Textbox
            label={`labels:inputs.password`}
            type="password"
            name={'password'}
            id={'password'}
            ref={register}
            placeholder={'placeholders.required'}
            errorForm={errors.password}
            isLabelRequired={dataInitial === undefined}
          />
        </Col>
        <Col sm={3} className="pt-3">
          <Controller
            as={ApiSelect}
            control={control}
            name="role"
            placeholder="Seleccione rol"
            label="labels:role"
            isLabelRequired={true}
            isSelectFirtsOption={!isUpdate}
            source={'role'}
            queryParams={{ all_roles: 1 }}
            selector={(option: any) => {
              return { label: option.name, value: option.name }
            }}
            errorForm={errors.role}
          />
        </Col>
      </Col>
      {dataInitial !== undefined && (
        <Col sm={12} className="pl-0 pr-0 pt-3">
          <Col sm={3}>
            <div className="d-flex">
              <label className="pr-3">
                <b>¿ {caps('labels:active')} ?:</b>
              </label>
              <input
                type="checkbox"
                name={'status'}
                id={'status'}
                ref={register}
                style={{ width: '20px', height: '20px' }}
              />
            </div>
          </Col>
        </Col>
      )}
      <Col sm={12} className="pt-4">
        <label>
          <b>
            {caps('labels:equipments')}
            <span className="text-danger"> (*)</span>:
          </b>
        </label>
        <Controller
          control={control}
          as={DualListBox}
          id={'equipments'}
          name={'equipments'}
          options={equipments}
          selected={equipmentSelected}
          canFilter
          filterPlaceholder={'Buscar servidor...'}
          showHeaderLabels={true}
          lang={DualListLang}
          onChange={(data) => {
            setEquipmentSelected(data[0])
            return data[0]
          }}
        />
        <ErrorMessage errors={errors} name="equipments">
          {({ message }) => <small className="text-danger">{message}</small>}
        </ErrorMessage>
      </Col>
      <Buttons.Form isLoading={isSaving} />
    </Form>
  )
}
export default FormUser

export class NumberUtils {
  //rellenar al principio de la cadena
  static padStart = (n: number, count: number, pad: string) =>
    n.toString().padStart(count, pad)

  //rellenar al final de la cadena
  static padEnd = (n: number, count: number, pad: string) =>
    n.toString().padEnd(count, pad)

  //reemplazar string en numero
  static replaceStringInNumber = (amount: number | string) => {
    amount = amount.toString()
    return parseFloat(amount.replace(/[^0-9.]/g, ''))
  }

  //convertir en float con cantidad de decimales especificos
  static fixedDimanic = (value: number, decimals = 1) => {
    return Number(value.toFixed(decimals))
  }

  //eliminar decimales
  static fixedDecimalZero = (value: number, decimals = 0) => {
    return parseFloat(value.toFixed(decimals))
  }

  //convertir en numero >= 0 ó retornar 0
  static number_format = (amount: string | number, decimals = 0) => {
    amount = amount.toString()
    amount = parseFloat(amount.replace(/[^0-9.]/g, ''))

    if (isNaN(amount) || amount === 0) return parseFloat('0').toFixed(decimals)

    amount = amount.toFixed(decimals)

    const amount_parts = amount.split('.'),
      regexp = /(\d+)(\d{3})/

    while (regexp.test(amount_parts[0]))
      amount_parts[0] = amount_parts[0].replace(regexp, '$1' + ',' + '$2')

    return amount_parts.join('.')
  }
}

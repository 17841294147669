//dependencies
import { IntlShape } from 'react-intl'

//internals
import { localizeIntl } from './LocalizationUtils'
import { $v } from './Reimports'

export class Utils {
  //genera un codigo UID
  static getGuid() {
    const S4 = function () {
      return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1)
    }
    return (
      '_' +
      S4() +
      S4() +
      '-' +
      S4() +
      '-' +
      S4() +
      '-' +
      S4() +
      '-' +
      S4() +
      S4() +
      S4()
    )
  }

  //capitalizar
  static capitalize(intl: IntlShape) {
    const get = localizeIntl(intl)
    return (id: string, args?: any) => $v.capitalize(get(id, args))
  }
}

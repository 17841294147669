import { AxiosError } from 'axios'
import { useFullIntl } from '../Hooks/useFullIntl'

export class HttpUtils {
  /**
   * localizar el mensaje para el codigo de error
   * @param code : HttpErrorCode
   * @returns nombre del error : string
   */
  static localizeErrorCode(code: number): string {
    switch (code) {
      case 401:
        return 'errors:common.not_authorized'
      case 404:
        return 'errors:common.not_found'
      case 403:
        return 'errors:common.not_authorized'
      default:
        return 'not_expected'
    }
  }

  /**
   * localizar mensajes de error en respuesta axios
   * @param data : AxiosError
   * @returns mensaje de error : string
   */
  static localizedErrorMsg(data: AxiosError): string {
    const errorWithCode = this.localizeErrorCode(data.response!.status)

    if (errorWithCode !== 'not_expected') {
      return errorWithCode
    }

    if (data.response) {
      if (data.response.data.errors) {
        return data.response.data.errors
      }
      if (data.response.data.message) {
        return data.response.data.message
      }
    }

    return data.message
  }
}

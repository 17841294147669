import React, { useCallback, useEffect, useState } from 'react'
import { Col } from 'react-bootstrap'
import { useShowAlertsRequest } from '../../../Common/Hooks/useShowAlertsRequest'
import { NumberUtils } from '../../../Common/Utils/NumberUtils'
import { LoadingSpinner } from '../../../Components/Common/LoadingSpinner'
import { ShowMessageInModule } from '../../../Components/Common/ShowMessageInModule'
import { BaseContentView } from '../../../Components/views/BaseContentView'
import { getDataDashboardService } from '../../../Services/ReportesServices/Dashboard'
import BoxValueGauge from '../UsageReport/components/BoxValueGauge'
import CotainerGadge from '../UsageReport/components/CotainerGadge'
import LevelMeasurementsChart from '../UsageReport/components/LevelMeasurementsChart'
import { IDashboardData } from './interfaces'

function DashboardReport() {
  //states
  const [IsLoadingData, setIsLoadingData] = useState<boolean>(true)
  const [errorInModule, setErrorInModule] = useState<string | null>(null)
  const [dataDashboard, setDataDashboard] = useState<IDashboardData[]>([])
  //hooks
  const showToast = useShowAlertsRequest()

  //handles IDashboardData
  const getDataUsageReport = async () => {
    setIsLoadingData(true)
    const response = await getDataDashboardService()
    if (response.appearanceMessage == 'success') {
      const resDataDashboard = response.responseMessage.data;
      const errorMsgModule: string | null = resDataDashboard.length === 0 ? 'No se encontraron equipos' : null
      setErrorInModule(errorMsgModule)
      setDataDashboard(resDataDashboard)
    } else {
      setErrorInModule('Ha ocurrido un error')
      showToast({ msgs: response })
    }
    setIsLoadingData(false)
  }

  const getGraphsComponents = useCallback(() => {
    const graphs: (JSX.Element | undefined)[] = []

    dataDashboard.forEach((dataEquipoDashboard, index) => {
      graphs.push(
        <Col sm={6} className="mb-2" style={{ minHeight: '41vh' }} key={`window-dashboard-${index}`}>
          <CotainerGadge icon="fas fa-chart-area"
            title={`${dataEquipoDashboard.client} - ${dataEquipoDashboard.equipo}`}
            subTitle={dataEquipoDashboard.date_update}>
            <Col sm={12} className="py-2 px-0" >
              <Col sm={8} className="mb-4 mb-md-0 p-2" style={{ height: '35vh' }}>
                <LevelMeasurementsChart
                  coordinates={dataEquipoDashboard.coordinates}
                  nominal={dataEquipoDashboard.nominals}
                />
              </Col>
              <Col sm={4} style={{ height: '35vh' }} className='p-2'>
                <Col className='d-flex flex-column h-100 p-0'>
                  <BoxValueGauge title='Ocupación'
                    value={dataEquipoDashboard.output_data?.occupation
                      ? NumberUtils.fixedDimanic(dataEquipoDashboard.output_data?.occupation, 2) : undefined}
                    unit="%" isInlineUnit={true} enableAlarm={true} />
                  <BoxValueGauge title='Masa'
                    value={dataEquipoDashboard.output_data?.ball_load}
                    unit="ton" isInlineUnit={true} />
                  <BoxValueGauge title='Volumen'
                    value={dataEquipoDashboard.output_data?.ball_volume}
                    unit="m" supUnit={'3'} isInlineUnit={true} />
                  <BoxValueGauge title='Batería'
                    value={dataEquipoDashboard.output_data?.battery_percentage
                      ? NumberUtils.fixedDimanic(dataEquipoDashboard.output_data?.battery_percentage, 2) : undefined}
                    unit="%" isInlineUnit={true} enableAlarm={true} />
                </Col>
              </Col>
            </Col>
          </CotainerGadge>
        </Col>
      )
    })
    return graphs
  }, [dataDashboard, IsLoadingData])

  //effects
  useEffect(() => {

    getDataUsageReport()
  }, [])

  return (
    <BaseContentView classStyle='pt-0 p-0' title='Niveles de buzón'>
      {IsLoadingData
        ? <LoadingSpinner />
        : errorInModule !== null
          ? (<Col><ShowMessageInModule message={errorInModule} /> </Col>)
          : (<>
            {getGraphsComponents()}
          </>)
      }
    </BaseContentView >
  )
}

export default DashboardReport
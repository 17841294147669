import React from 'react'
import { Col } from 'react-bootstrap'
import { Buttons } from '../../../Components/Common/Buttons'
import { BaseContentView } from '../../../Components/views/BaseContentView'
import FormEquipment from './Components/FormEquipment'

const AddEquipment = () => {
  return (
    <BaseContentView title="Agregar equipo">
      <Col className="mb-4">
        <Buttons.Back />
      </Col>
      <Col className="mb-3 p-0">
        <FormEquipment />
      </Col>
    </BaseContentView>
  )
}

export default AddEquipment

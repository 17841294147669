import { AxiosError, AxiosResponse } from 'axios'
import { ax } from '../../../Common/Utils/AxiosCustom'
import { HttpUtils } from '../../../Common/Utils/HttpUtils'
import { $j } from '../../../Common/Utils/Reimports'
import { IServicesResponse } from '../../../Data/interfaces/Common/ServicesResponse'
import { IRolesAndPermisions } from '../../../Data/interfaces/Config/Rol'
import { IPermissionModule } from '../../../Pages/Config/roles/components/DetalleRol'

export const getAllRolesService = async (
  onSuccess: (data: IRolesAndPermisions) => void,
  onError: (msg: IServicesResponse<undefined>) => void,
  onFinish: () => void
) => {
  await ax
    .get<IRolesAndPermisions>('role')
    .then((res: AxiosResponse) => {
      onSuccess(res.data)
    })
    .catch((error: AxiosError) => {
      onError({
        appearanceMessage: 'error',
        responseMessage: HttpUtils.localizedErrorMsg(error),
      })
    })
    .finally(() => {
      onFinish()
    })
}

export const postRolesService = async (
  data: any,
  onSuccess: () => void,
  onError: (error: IServicesResponse<undefined>) => void
) => {
  await ax
    .post('role', { name: data.nombre })
    .then(() => {
      onSuccess()
    })
    .catch((error: AxiosError) => {
      onError({
        appearanceMessage: 'error',
        responseMessage: HttpUtils.localizedErrorMsg(error),
      })
    })
}

export const setRoleService = async (
  roleId: string,
  roleName: string,
  onSuccess: (msg: IServicesResponse<undefined>) => void,
  onError: (msg: IServicesResponse<undefined>) => void
) => {
  await ax
    .patch($j('role/update', roleId), {
      name: roleName,
    })
    .then((res: AxiosResponse) => {
      onSuccess({
        appearanceMessage: 'success',
        responseMessage: res.data,
      })
    })
    .catch((error: AxiosError) => {
      onError({
        appearanceMessage: 'error',
        responseMessage: HttpUtils.localizedErrorMsg(error),
      })
    })
}

export const setRolePermissionsModuleService = async (
  data: IPermissionModule,
  onFinish: (msg: IServicesResponse<undefined>) => void
) => {
  const serviceResponse: IServicesResponse<undefined> = {
    appearanceMessage: 'error',
  }

  await ax
    .patch('role/assign-permissions', {
      role: data.role,
      module_id: data.module_id,
      permission_level: data.permission_level,
    })
    .then((res: AxiosResponse) => {
      serviceResponse.appearanceMessage = 'success'
      serviceResponse.responseMessage = res.data
    })
    .catch((error: AxiosError) => {
      serviceResponse.appearanceMessage = 'error'
      serviceResponse.responseMessage = HttpUtils.localizedErrorMsg(error)
    })
    .finally(() => {
      onFinish(serviceResponse)
    })
}

export const deleteRoleService = async (
  roleId: string,
  onSuccess: () => void,
  onFinish: (msg: IServicesResponse<undefined>) => void
) => {
  const serviceResponse: IServicesResponse<undefined> = {
    appearanceMessage: 'error',
  }

  await ax
    .delete($j('role/delete', roleId))
    .then((res: AxiosResponse) => {
      serviceResponse.appearanceMessage = 'success'
      serviceResponse.responseMessage = res.data
      onSuccess()
    })
    .catch((error: AxiosError) => {
      serviceResponse.appearanceMessage = 'error'
      serviceResponse.responseMessage = HttpUtils.localizedErrorMsg(error)
    })
    .finally(() => {
      onFinish(serviceResponse)
    })
}

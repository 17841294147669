import React, { useEffect, useRef, useState } from 'react'
import { Wrapper, Status } from '@googlemaps/react-wrapper'
import { ShowMessageInModule } from '../../../../Components/Common/ShowMessageInModule'
import { LoadingSpinner } from '../../../../Components/Common/LoadingSpinner'
import { Col } from 'react-bootstrap'

const render = (status: Status) => {
  switch (status) {
    case Status.FAILURE:
      return <Col sm={12} className='p-0 d-flex flex-column align-items-center justify-content-center'>
        <i className="fas fa-map-marker-alt-slash fa-3x" color="red" />
        Ha ocurrido un error!
      </Col>
    case Status.LOADING:
      return <Col>
        <LoadingSpinner />
      </Col>
    default:
      return <Col>
        <LoadingSpinner />
      </Col>
  }
}
const TooltipMap = ({
  center,
  client,
}: {
  center: google.maps.LatLngLiteral
  client?: string
}) => {
  return `
    <div class="flex flex-col content-start p-2" style="color: #002060;">
      <h4 class="font-semibold text-lg mb-2">${client}</h4>
      <p><b>Latitud: ${center.lat}</b></p>
      <p><b>Longitud: ${center.lng}</b></p>
    </div>`
}
const MapComponent = ({
  center,
  zoom,
  client,
}: {
  center: google.maps.LatLngLiteral
  zoom: number
  client?: string
}) => {
  const mapRef = useRef<HTMLDivElement>(null)
  let map: google.maps.Map
  const infoWindow = new google.maps.InfoWindow()

  const showMarkers = () => {
    const marker: google.maps.Marker = new window.google.maps.Marker({
      position: center,
    })
    marker.addListener('click', () => {
      infoWindow.setContent(TooltipMap({ center, client }))

      infoWindow.open({
        anchor: marker,
        map,
        shouldFocus: true,
      })
    })
    marker.setMap(map)
  }

  useEffect(() => {
    map = new window.google.maps.Map(mapRef.current!, {
      center,
      zoom,
      mapTypeId: 'satellite',
    })
    if (mapRef.current) {
      showMarkers()
    }
  })

  return <div ref={mapRef} id="map" style={{ width: '100%', height: '100%' }} />
}

export const GoogleMapMarker = ({
  lat,
  lng,
  client,
  isLoading,
}: {
  lat: string | undefined
  lng: string | undefined
  client?: string,
  isLoading?: boolean
}) => {
  const [Center, setCenter] = useState<google.maps.LatLngLiteral | undefined>(undefined)


  // = { lat: lat, lng: lng }
  const zoom = 13

  const KEY_MAPS = process.env.REACT_APP_KEY_GOOGLE_MAP

  useEffect(() => {
    if (lat !== undefined && lng !== undefined) {
      setCenter({
        lat: parseFloat(lat),
        lng: parseFloat(lng)
      });
    } else {
      setCenter(undefined)
    }
  }, [lat, lng])

  return isLoading ?
    <Col>
      <LoadingSpinner />
    </Col>
    : KEY_MAPS ? (
      Center !== undefined ? (
        <Wrapper apiKey="AIzaSyD6AUPIR0eIiGldIIo0b06uqLxlZDyQh-I" render={render}>
          <MapComponent center={Center} zoom={zoom} client={client} />
        </Wrapper>
      ) : (
        <Col sm={12} className='p-0 d-flex flex-column align-items-center justify-content-center'>
          <i className="fas fa-map-marker-alt fa-3x" style={{ color: "#ea4335" }} />
          No hay datos de ubicación
        </Col>
      )
    ) : (
      <ShowMessageInModule message="Por favor proporcione el key de google map" />
    )
}

import React from 'react'
import { Modal } from 'react-bootstrap'
import { useFullIntl } from '../../../../Common/Hooks/useFullIntl'
import { roleChange } from '../../../../Data/interfaces/Config/Rol'
import { DetalleRol } from './DetalleRol'

const CambiarPermisos = ({
  modal,
  setReload,
  dataOfChangeOfPermission,
  setDataOfChangeOfPermission,
}: {
  modal: any
  dataOfChangeOfPermission: roleChange
  setDataOfChangeOfPermission: (data: roleChange) => void
  setReload: (reload: boolean) => void
}) => {
  //hooks
  const { capitalize: caps } = useFullIntl()

  //handles
  function handleUpdatePermissions(permission: number) {
    setDataOfChangeOfPermission({ ...dataOfChangeOfPermission, permission })
  }

  function handleDeleteRole() {
    setReload(true)
    modal.hide()
  }

  async function handlePostPermissions() {
    setReload(true)
    modal.hide()
  }

  return (
    <Modal show={modal.visible} onHide={modal.hide}>
      <Modal.Header closeButton>
        <b>{caps('titles:changePermisson')}</b>
      </Modal.Header>
      <Modal.Body>
        {dataOfChangeOfPermission.roleName && (
          <DetalleRol
            role={dataOfChangeOfPermission.roleName ?? ''}
            color="var(--secondary)"
            role_id={dataOfChangeOfPermission.roleId ?? 0}
            permissionData={dataOfChangeOfPermission}
            modules={[
              {
                name: dataOfChangeOfPermission.entityName ?? '',
                permission: dataOfChangeOfPermission.permission ?? 0,
                id: dataOfChangeOfPermission.entityId ?? 0,
              },
            ]}
            onChangePermission={handleUpdatePermissions}
            onDelete={handleDeleteRole}
            handleSave={handlePostPermissions}
          />
        )}
      </Modal.Body>
    </Modal>
  )
}

export default CambiarPermisos

import React, { useEffect, useState } from 'react'
import { Col } from 'react-bootstrap'
import { useNavigation } from '../../../Common/Hooks/useNavigation'
import { Buttons } from '../../../Components/Common/Buttons'
import { LoadingSpinner } from '../../../Components/Common/LoadingSpinner'
import { BaseContentView } from '../../../Components/views/BaseContentView'
import { IServicesResponse } from '../../../Data/interfaces/Common/ServicesResponse'
import { IUsuarioForm } from '../../../Data/interfaces/Config/Usuario'
import { getSingleUserService } from '../../../Services/Config/users/UserServices'
import FormUser from './Components/FormUser'

export const UpdateUser = () => {
  //hooks
  const { stateAs, goto } = useNavigation()
  const { data } = stateAs()

  //states
  const [user, setUser] = useState<IUsuarioForm>()
  const [isLoadModule, setIsLoadModule] = useState<boolean>(true)

  //effects
  useEffect(() => {
    const fetchData = async () => {
      setIsLoadModule(true)
      const responseService: IServicesResponse<IUsuarioForm> =
        await getSingleUserService(data.username)
      responseService.appearanceMessage === 'success' &&
        setUser(responseService.responseMessage as IUsuarioForm)
      setIsLoadModule(false)
    }
    data !== undefined && data !== null
      ? fetchData()
      : goto.absolute('base.users')
  }, [data])

  return (
    <BaseContentView title="Modificar Usuario">
      {isLoadModule ? (
        <LoadingSpinner />
      ) : (
        <>
          <Col className="mb-4">
            <Buttons.Back />
          </Col>
          <Col className="mb-3 p-0">
            <FormUser dataInitial={user} isUpdate={true} />
          </Col>
        </>
      )}
    </BaseContentView>
  )
}

import React, { useState, useCallback, useEffect, Fragment } from 'react'
import { useFullIntl } from '../../Common/Hooks/useFullIntl'
import { usePushError } from '../../Common/Hooks/usePushError'
import { ax } from '../../Common/Utils/AxiosCustom'
import { $u, $v } from '../../Common/Utils/Reimports'

import Select from 'react-select'
import './test.css'
import { CSSProperties } from 'styled-components'
import { FieldError } from 'react-hook-form'

interface Props<T> {
  name?: string
  label?: string
  span?: number
  errors?: string[]
  source: string | T[]
  value?: string
  placeholder?: string
  noneLabel?: string
  queryParams?: any
  showNone?: boolean
  className?: string
  multiple?: boolean
  filter?: (x: OptionType) => boolean
  onChange?: (value: any) => void
  onChangeMultiple?: (value: string[]) => void
  selector: (data: T) => OptionType
  isDisabled?: boolean
  valueInObject?: boolean
  reload?: boolean
  menuPortalTarget?: 'body' | 'parent'
  wildcardOption?: OptionType
  styleContainer?: CSSProperties
  isLabelRequired?: boolean
  errorForm?: FieldError
  onFinishLoad?: (optionsSize: number) => void
  isSelectFirtsOption?: boolean
}

interface State<T> {
  data: T[]
  loading: boolean
  init: boolean
  disabled: boolean
}

export interface ValueDisplay {
  value: string
  display: string
}

interface OptionType {
  value: string
  label: string
}

export const ApiSelect = <T extends unknown>(props: Props<T>) => {
  const { capitalize: caps } = useFullIntl()
  const { onChange, selector, filter, valueInObject } = props
  const { pushError } = usePushError()
  const [value, setValue] = useState<string>()

  const initial: State<T> = {
    data: [],
    loading: true,
    disabled: true,
    init: false,
  }
  const [state, setState] = useState(initial)

  const setLoading = useCallback((loading: boolean) => {
    setState((s) => $u(s, { $merge: { loading } }))
    if (!props.isDisabled) {
      setState((s) => $u(s, { disabled: { $set: loading } }))
    }
  }, [])

  useEffect(() => {

    if (onChange != null) {
      if (props.value != undefined) {
        onChange(props.value);
      } else {
        if (state.data.length > 0) {
          onChange(valueInObject ? state.data[0] as string : selector(state.data[0]).value);
        }
      }
    }
  }, [props.value]);


  const mappingSourceArray = () => {
    if (Array.isArray(props.source)) {
      const d =
        filter == null
          ? props.source
          : props.source.filter((x) => filter(selector(x)))
      setState((s) => $u(s, { $merge: { data: d ?? [] } }))
    }
  }

  async function fetch() {
    setLoading(true)


    if (typeof props.source === 'string') {
      await ax.get<T[]>(props.source, { params: props.queryParams }).then((response) => {
        if (response.data) {
          const d = filter == null ? response.data : response.data.filter((x) => filter(selector(x)));

          setState((s) => $u(s, { $merge: { data: d ?? [] } }));

          if (d.length > 0 && onChange != null && props.value == undefined) {
            if (props.isSelectFirtsOption === undefined || props.isSelectFirtsOption) {
              handleChange(selector(d[0]));
            }
            // onChange(valueInObject ? selector(d[0]) :  selector(d[0]).value );
          }
          props.onFinishLoad && props.onFinishLoad(d.length)
        }
      })
        .catch(() => pushError('errors.enumLoad'));
    } else {
      mappingSourceArray();
    }
    setLoading(false)
  }

  useEffect(() => {
    if (state.init) {
      return
    }
    if (!props.reload) {
      fetch()
    }
  }, [])

  useEffect(() => {
    if (props.reload) {
      fetch()
    }
  }, [props.reload])

  // }, [props.source, setLoading, pushError, props.queryParams, state.init, filter, selector, onChange, props.value]);

  useEffect(() => {
    mappingSourceArray()
  }, [props.source])

  function handleChange(e: any) {
    setValue(e.value)
    if (props.onChange != null) {
      props.onChange(valueInObject ? e : e.value)
    }
  }

  // const options = () => {
  //   const optionsValues: OptionType[] = []
  //   if (props.wildcardOption != null) {
  //     optionsValues.push(props.wildcardOption)
  //   }

  //   const values: OptionType[] = state.data.map((e) => {
  //     const val = selector(e)
  //     return {
  //       value: val.value,
  //       label: $v.titleCase(val.display),
  //     }
  //   })

  //   return optionsValues.concat(values)
  // }

  // const optionsValue = options()?.filter((o) => {
  //   if (props.value?.toString() != undefined) {
  //     if (
  //       o.value.toString() === props.value?.toString() ||
  //       o.value.toString() === (props.value as any).value
  //     ) {
  //       return { label: caps(o.label), value: o.value }
  //     }
  //   } else {
  //     if (o.value.toString() === props.value?.toString() || o.value === value) {
  //       return { label: caps(o.label), value: o.value }
  //     }
  //   }
  // })

  const optionsMapped = () => {

    const options = state.data.map((e) => {
      const val = selector(e);
      return val

    })

    return options;
  };

  const options = optionsMapped();

  const optionsValue = options?.filter((o) => {
    if (props.value?.toString() != undefined) {
      if ((o.value.toString() === props.value?.toString()) || (o.value.toString() === (props.value as any).value)) {
        return { label: caps(o.label), value: o.value }
      }
    } else {
      if (o.value.toString() === props.value?.toString() || o.value === value) {
        return { label: caps(o.label), value: o.value }
      }
    }
  });

  return (
    <div className={'ServerSelect ' + (props.span ? 'col-' + props.span : '')}>
      {props.label && (
        <label>
          <b>
            {caps(props.label)}{' '}
            {props.isLabelRequired && <span className="text-danger"> (*)</span>}
            :
          </b>
        </label>
      )}
      <Select
        id="select"
        name={props.name}
        placeholder={
          props.placeholder == null ? undefined : caps(props.placeholder)
        }
        options={options}
        getOptionLabel={({ label }) => label}
        getOptionValue={({ value }) => value}
        onChange={handleChange}
        value={optionsValue}
        isDisabled={props.isDisabled}
        styles={{
          control: (base) => ({
            ...base,
            minHeight: 'calc(1.5em + 1.25rem + 1.75px)',
            borderColor: '#e3eaef',
            borderRadius: '0.25rem',
          }),
          indicatorsContainer: (base) => ({
            ...base,
            div: { padding: 5 },
          }),
          container: (base) => ({
            ...base,
            ...props.styleContainer,
          }),
        }}
        isLoading={state.loading}
        menuPortalTarget={
          props.menuPortalTarget == 'body'
            ? document.body
            : document.parentElement
        }
      />
      {props.errors && props.errors.length > 0 && (
        <div>
          {props.errors.map((e, i) => (
            <Fragment key={i}>
              <small className="text-danger" key={i}>
                {e}
              </small>
              <br />
            </Fragment>
          ))}
        </div>
      )}

      {props.errorForm && (
        <div>
          <small className="text-danger">{props.errorForm.message}</small>
        </div>
      )}
    </div>
  )
}

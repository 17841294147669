import { PropsWithChildren } from 'react'
import React from 'react'
import { useFullIntl } from '../../Common/Hooks/useFullIntl'
import { Row, Col } from 'react-bootstrap'

interface Props {
  title?: string
  classStyle?: string
}
// 002060
export const BaseContentView = (props: PropsWithChildren<Props>) => {
  const { capitalize: caps } = useFullIntl()
  return (
    <div
      className={`container-fluid ${props.classStyle !== undefined && props.classStyle}`}
      style={{ minHeight: '93vh' }}
    >
      {props.title && (
        <Col sm={12} className="mb-4 pt-3">
          <h3>{caps(props.title)}</h3>
        </Col>
      )}
      {props.children}
    </div>
  )
}

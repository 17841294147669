
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { BounceLoader } from "react-spinners"

export const LoadingSpinner = () => {
  return (
    <Row style={{ height: '100%', width: '100%', position: 'absolute' }}>
      <Col className='d-flex'>
        <BounceLoader color='var(--primary)' css={{ margin: 'auto' } as any} size={64} />
      </Col>
    </Row>
  )
}
import React from 'react'
import { CambiarContrasena } from '../Pages/Auth/CambiarContrasena'
import { CompanyProfile } from '../Pages/Config/profile/CompanyProfile'
import { AdministrarRoles } from '../Pages/Config/roles/AdministrarRoles'
import { AddUser } from '../Pages/Config/users/AddUser'
import { IndexUsers } from '../Pages/Config/users/IndexUsers'
import { UpdateUser } from '../Pages/Config/users/UpdateUser'
import { Home } from '../Pages/Home'
import AddAlarmsEquipment from '../Pages/Masters/AlarmsEquipments/AddAlarmsEquipment'
import IndexAlarmsEquipments from '../Pages/Masters/AlarmsEquipments/IndexAlarmsEquipments'
import UpdateAlarmsEquipment from '../Pages/Masters/AlarmsEquipments/UpdateAlarmsEquipment'
import AddClient from '../Pages/Masters/Clients/AddClient'
import { IndexClients } from '../Pages/Masters/Clients/IndexClients'
import UpdateClient from '../Pages/Masters/Clients/UpdateClient'
import AddEquipment from '../Pages/Masters/Equipments/AddEquipment'
import IndexEquipments from '../Pages/Masters/Equipments/IndexEquipments'
import UpdateEquipment from '../Pages/Masters/Equipments/UpdateEquipment'
import { NotFound } from '../Pages/NotFound'
import DashboardReport from '../Pages/Reportes/Dashboard/DashboardReport'
import UsageReport_v2 from '../Pages/Reportes/UsageReport/UsageReport_v2'

interface Route {
  path: string
  exact?: boolean
  component: JSX.Element
  element?: string
}

interface RouteGroup {
  prefix: string
  routes?: Route[]
}

const routes: RouteGroup[] = [
  {
    prefix: 'routes:base.root',
    routes: [
      { path: 'routes:meta.base', component: <Home /> },
      { path: 'routes:meta.not_found', component: <NotFound /> },
    ],
  },
  {
    prefix: 'routes:base.change_password',
    routes: [{ path: 'routes:meta.base', component: <CambiarContrasena /> }],
  },
  {
    prefix: 'routes:base.users',
    routes: [
      { path: 'routes:meta.base', component: <IndexUsers /> },
      { path: 'routes:meta.add', component: <AddUser /> },
      { path: 'routes:meta.modify', component: <UpdateUser /> },
    ],
  },
  {
    prefix: 'routes:base.role',
    routes: [{ path: 'routes:meta.base', component: <AdministrarRoles /> }],
  },
  // {
  //   prefix: "routes:base.parameters",
  //   routes: [{ path: "routes:meta.base", component: <Parametros /> }],
  // },
  {
    prefix: 'routes:base.company',
    routes: [{ path: 'routes:meta.base', component: <CompanyProfile /> }],
  },

  //MODULOS
  {
    prefix: 'routes:base.stock-level',
    routes: [{ path: 'routes:meta.base', component: <UsageReport_v2 /> }],
  },
  {
    prefix: 'routes:base.dashboard',
    routes: [{ path: 'routes:meta.base', component: <DashboardReport /> }],
  },
  {
    prefix: 'routes:base.client',
    routes: [
      { path: 'routes:meta.base', component: <IndexClients /> },
      { path: 'routes:meta.add', component: <AddClient /> },
      { path: 'routes:meta.modify', component: <UpdateClient /> },
    ],
  },
  {
    prefix: 'routes:base.equipment',
    routes: [
      { path: 'routes:meta.base', component: <IndexEquipments /> },
      { path: 'routes:meta.add', component: <AddEquipment /> },
      { path: 'routes:meta.modify', component: <UpdateEquipment /> },
    ],
  },
  {
    prefix: 'routes:base.equipments_alarms',
    routes: [
      { path: 'routes:meta.base', component: <IndexAlarmsEquipments /> },
      { path: 'routes:meta.add', component: <AddAlarmsEquipment /> },
      { path: 'routes:meta.modify', component: <UpdateAlarmsEquipment /> },
    ],
  },
]

export default routes

import React, { CSSProperties, PropsWithChildren } from 'react'
import { Col } from 'react-bootstrap'

interface ICotainerGadgeProps {
  icon?: string
  sizeIcon?: string
  title: string
  subTitle?: string
  backgroundContainer?: string
}

function CotainerGadge({
  icon,
  sizeIcon = 'fa-lg',
  title,
  subTitle,
  children,
  backgroundContainer

}: PropsWithChildren<ICotainerGadgeProps>) {

  const styleHeader: CSSProperties = {
    background: '#002060',
    height: '2.5rem',
    color: '#fff'
  }

  return (
    <Col className="px-0" style={{
      borderRadius: '0.25rem',
      outline: '2px solid #002060',
      position: 'relative',
      background: backgroundContainer,
      height: '100%'
    }
    }>
      <Col sm={12} className='d-flex align-items-center py-3' style={styleHeader}>
        {icon && (<i className={`${icon} ${sizeIcon}`} />)}
        {
          subTitle ? (
            <Col className="d-flex pr-0">
              <Col sm={9} className="pl-0 text-truncate">
                <b>{title} </b>
              </Col>
              <Col sm={3} className="pl-0 text-right text-truncate" >
                <b>{subTitle}</b>
              </Col>
            </Col>
          ) : (
            <Col className="d-flex justify-content-center ">
              <b>{title}</b>
            </Col>
          )
        }
      </Col>
      <Col sm={12} className='d-flex px-0' style={{ height: 'calc(100% - 2.5rem)', width: '100%' }}>
        {children}
      </Col>
    </Col>
  )
}

export default CotainerGadge
import React, { useEffect, useState } from 'react'
import { Button, Col, Modal } from 'react-bootstrap'
import { useFullIntl } from '../../../../Common/Hooks/useFullIntl'
import { useShowAlertsRequest } from '../../../../Common/Hooks/useShowAlertsRequest'
import { Textbox } from '../../../../Components/Forms/Textbox'
import { IServicesResponse } from '../../../../Data/interfaces/Common/ServicesResponse'
import { IRoleSimple } from '../../../../Data/interfaces/Config/Rol'
import { setRoleService } from '../../../../Services/Config/roles/RolesServices'

const UpdateRolModal = ({
  modal,
  rol,
  setReload,
}: {
  modal: any
  rol: IRoleSimple | undefined
  setReload: (reload: boolean) => void
}) => {
  //hooks
  const { capitalize: caps } = useFullIntl()
  const showToast = useShowAlertsRequest()

  //states
  const [rolNombre, setRolNombre] = useState<string>('')
  const [errors, setErrors] = useState<string[]>()

  //handles
  const handleSetRol = async () => {
    if (rolNombre === undefined || rolNombre === '') {
      setErrors([caps('validations:required')])
      return
    }
    const onSuccess = (msg: IServicesResponse<undefined>) => {
      setReload(true)
      modal.hide()
      showToast({ msgs: msg })
    }
    const onError = (msg: IServicesResponse<undefined>) => {
      showToast({ msgs: msg })
    }

    await setRoleService(rol!.codigo.toString(), rolNombre, onSuccess, onError)
  }

  //effects
  useEffect(() => {
    rol !== undefined && setRolNombre(rol?.nombre.toUpperCase())
    setErrors([])
  }, [modal])

  return (
    <Modal show={modal.visible} onHide={modal.hide}>
      <Modal.Header closeButton>
        <b>
          {caps('titles:update_rol')} ({rol?.nombre.toUpperCase()})
        </b>
      </Modal.Header>
      <hr />
      <Modal.Body>
        <Col sm="12">
          <Textbox
            name="newNameRol"
            value={rolNombre}
            onChange={(value) => setRolNombre(value.toUpperCase())}
            errors={errors}
          />
        </Col>
        <Col sm={12} className="text-right">
          <Button
            className="btn-primary mt-5"
            id="btnNewQuote"
            onClick={handleSetRol}
          >
            <i className="fas fa-save mr-3" />
            {caps('labels:common.save')}
          </Button>
        </Col>
      </Modal.Body>
    </Modal>
  )
}

export default UpdateRolModal
